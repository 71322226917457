import { Address, CountryCodes } from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';

/**
 * Represents the structure of the current address form state.
 * Extracted from the main `Address` type.
 */
export type CurrentAddressForm = Pick<
  Address,
  | 'addressLine1'
  | 'addressLine2'
  | 'zipCode'
  | 'city'
  | 'state'
  | 'county'
  | 'moveInMonth'
  | 'moveInYear'
  | 'monthsAtAddress'
  | 'residenceTypeCode'
  | 'residenceTypeCodeText'
  | 'rentMortgagePaymentAmountString'
  | 'rentMortgagePaymentAmount'
  | 'countryCode'
  | 'coApplicantSameAddress'
>;

/**
 * Represents the structure of the previous address form state.
 * Extracted from the main `Address` type and only includes previous-prefixed fields.
 */
export type PreviousAddressForm = Pick<
  Address,
  | 'previousAddressLine1'
  | 'previousAddressLine2'
  | 'previousZipCode'
  | 'previousCity'
  | 'previousState'
  | 'previousCounty'
  | 'previousMoveInMonth'
  | 'previousMoveInYear'
  | 'previousMonthsAtAddress'
  | 'previousResidenceTypeCode'
  | 'previousResidenceTypeCodeText'
  | 'previousRentMortgagePaymentAmountString'
  | 'previousRentMortgagePaymentAmount'
  | 'previousCountryCode'
>;

// ------------------- Default Values & Helpers -------------------

/**
 * Represents the fully default-initialized `Address` object used for form state.
 * Includes both current and previous address fields.
 */
export const defaultAddressValues: Address = {
  addressLine1: '',
  addressLine2: '',
  zipCode: '',
  city: '',
  state: undefined,
  county: '',
  moveInMonth: undefined,
  moveInYear: undefined,
  monthsAtAddress: 0,
  residenceTypeCode: '',
  residenceTypeCodeText: '',
  rentMortgagePaymentAmountString: '',
  rentMortgagePaymentAmount: 0,
  countryCode: CountryCodes.UNITED_STATES,
  coApplicantSameAddress: false,

  previousAddressLine1: '',
  previousAddressLine2: '',
  previousZipCode: '',
  previousCity: '',
  previousState: undefined,
  previousCounty: '',
  previousMoveInMonth: undefined,
  previousMoveInYear: undefined,
  previousMonthsAtAddress: 0,
  previousResidenceTypeCode: '',
  previousResidenceTypeCodeText: '',
  previousRentMortgagePaymentAmountString: '',
  previousRentMortgagePaymentAmount: 0,
  previousCountryCode: CountryCodes.UNITED_STATES,
};

/**
 * Returns a default-initialized object for the current address form.
 * Used to reset or initialize the current address section of the form.
 */
export function getDefaultCurrentAddress(): CurrentAddressForm {
  const a = defaultAddressValues;
  return {
    addressLine1: a.addressLine1,
    addressLine2: a.addressLine2,
    zipCode: a.zipCode,
    city: a.city,
    state: a.state,
    county: a.county,
    moveInMonth: a.moveInMonth,
    moveInYear: a.moveInYear,
    monthsAtAddress: a.monthsAtAddress,
    residenceTypeCode: a.residenceTypeCode,
    residenceTypeCodeText: a.residenceTypeCodeText,
    rentMortgagePaymentAmountString: a.rentMortgagePaymentAmountString,
    rentMortgagePaymentAmount: a.rentMortgagePaymentAmount,
    countryCode: a.countryCode,
    coApplicantSameAddress: a.coApplicantSameAddress,
  };
}

/**
 * Returns a default-initialized object for the previous address form.
 * Used when the user has lived at their current address less than 2 years.
 */
export function getDefaultPreviousAddress(): PreviousAddressForm {
  const a = defaultAddressValues;
  return {
    previousAddressLine1: a.previousAddressLine1,
    previousAddressLine2: a.previousAddressLine2,
    previousZipCode: a.previousZipCode,
    previousCity: a.previousCity,
    previousState: a.previousState,
    previousCounty: a.previousCounty,
    previousMoveInMonth: a.previousMoveInMonth,
    previousMoveInYear: a.previousMoveInYear,
    previousMonthsAtAddress: a.previousMonthsAtAddress,
    previousResidenceTypeCode: a.previousResidenceTypeCode,
    previousResidenceTypeCodeText: a.previousResidenceTypeCodeText,
    previousRentMortgagePaymentAmountString: a.previousRentMortgagePaymentAmountString,
    previousRentMortgagePaymentAmount: a.previousRentMortgagePaymentAmount,
    previousCountryCode: a.previousCountryCode,
  };
}
