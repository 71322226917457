<template>
  <v-row v-if="localFormValues.coApplicantSameAddress">
    <v-col cols="12" lg="6" class="same-address-details">
      <p>{{ $t('current-address-label') }}</p>
      <p class="small" v-html="formattedAddress(false, false)"></p>
      <!--use non previous (isPrevious: boolean) + use primary buyer(isCobuyer: boolean) for same address as primary buyer-->
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="12" lg="6" class="py-0">
      <v-text-field
        v-model="localFormValues[getFieldKey('addressLine1')]"
        :label="buyerAddressSchema?.[getFieldKey('addressLine1')]?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.addressLine1}`"
        :error-messages="
          hasError(getFieldKey('addressLine1'))
            ? [
                buyerAddressSchema?.[getFieldKey('addressLine1')]?.validationMessage ||
                  'Enter a valid street',
              ]
            : []
        "
      />
    </v-col>
    <v-col cols="12" lg="6" class="py-0">
      <v-text-field
        v-model="localFormValues[getFieldKey('addressLine2')]"
        :label="buyerAddressSchema?.[getFieldKey('addressLine2')]?.label"
        :data-testid="`sca-${getFieldKey('addressLine2')}`"
        :error-messages="
          hasError(getFieldKey('addressLine2'))
            ? [
                buyerAddressSchema?.[getFieldKey('addressLine2')]?.validationMessage ||
                  'Enter a valid street',
              ]
            : []
        "
      />
    </v-col>
  </v-row>

  <v-row v-if="!formValues.coApplicantSameAddress">
    <v-col cols="12" md="8" lg="6" class="py-0">
      <v-text-field
        v-model="localFormValues[getFieldKey('city')]"
        :label="buyerAddressSchema?.[getFieldKey('city')]?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.city}`"
        :error-messages="
          hasError(getFieldKey('city'))
            ? [buyerAddressSchema?.[getFieldKey('city')]?.validationMessage || 'Enter a valid city']
            : []
        "
      />
    </v-col>
    <v-col cols="6" md="4" lg="3" xl="2" class="py-0">
      <v-select
        v-model="localFormValues[getFieldKey('state')]"
        :label="buyerAddressSchema?.[getFieldKey('state')]?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.state}`"
        :items="stateOptions"
        variant="underlined"
        :error-messages="
          hasError(getFieldKey('state'))
            ? [
                buyerAddressSchema?.[getFieldKey('state')]?.validationMessage ||
                  'Enter a valid state',
              ]
            : []
        "
      />
    </v-col>
    <v-col cols="6" md="4" lg="3" xl="2" class="py-0">
      <v-text-field
        v-model="localFormValues[getFieldKey('zipCode')]"
        :label="buyerAddressSchema?.[getFieldKey('zipCode')]?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.zipCode}`"
        @keydown="onlyAllowNumbers"
        @paste.prevent="handlePaste"
        :error-messages="
          hasError(getFieldKey('zipCode'))
            ? [
                buyerAddressSchema?.[getFieldKey('zipCode')]?.validationMessage ||
                  'Enter a valid zipcode',
              ]
            : []
        "
        minlength="5"
        maxlength="5"
        type="tel"
      />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" class="sca-residence-label">
      <p class="logic-selector-label">
        {{
          fieldsMap.get(
            isPrevious
              ? coPrefix + 'previous-address.residenceTypeCode.Label'
              : coPrefix + 'current-address.residenceTypeCode.Label'
          )?.value
        }}
      </p>
    </v-col>
    <v-col cols="12" class="toggle-buttons mb-4 py-0">
      <v-btn-toggle
        v-model="localFormValues[getFieldKey('residenceTypeCode')]"
        :data-testid="`sca-${getFieldKey('residenceTypeCode')}`"
        class="logic-selector"
        mandatory
      >
        <v-btn
          v-for="(option, index) in residenceTypeOptions"
          :key="index"
          :value="option.value"
          class="residence-type-option"
          :data-testid="`sca-${getFieldKey('residenceTypeCode')}-${option.value}`"
          :error-messages="
            hasError(getFieldKey('residenceTypeCode'))
              ? [
                  buyerAddressSchema?.[getFieldKey('residenceTypeCode')]?.validationMessage ||
                    'Select a residence',
                ]
              : []
          "
        >
          {{ option.text }}
        </v-btn>
      </v-btn-toggle>
      <!-- TODO: duplicate the out of the box styling here - v-btn-toggle doesn't provide it like other inputs -->
      <p v-if="hasError(getFieldKey('residenceTypeCode'))" class="error-text">
        {{
          buyerAddressSchema?.[getFieldKey('residenceTypeCode')]?.validationMessage ||
          'Select a residence type.'
        }}
      </p>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6" lg="4" class="move-in-month" v-if="residenceTypeCodeSelected">
      <v-select
        v-model="localFormValues[getFieldKey('moveInMonth')]"
        :label="fieldsMap.get(coPrefix + 'current-address.moveInMonth.Label')?.value"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.moveInMonth}`"
        :items="validMonthOptions"
        item-title="text"
        item-value="value"
        :error-messages="
          hasError(getFieldKey('moveInMonth'))
            ? [
                buyerAddressSchema?.[getFieldKey('moveInMonth')]?.validationMessage ||
                  'Enter a valid move in month.',
              ]
            : []
        "
      />
    </v-col>
    <v-col cols="6" lg="4" class="move-in-year" v-if="residenceTypeCodeSelected">
      <v-select
        v-model="localFormValues[getFieldKey('moveInYear')]"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.moveInYear}`"
        :label="fieldsMap.get(coPrefix + 'current-address.moveInYear.Label')?.value"
        :items="validYearOptions"
        :error-messages="
          hasError(getFieldKey('moveInYear'))
            ? [
                buyerAddressSchema?.[getFieldKey('moveInYear')]?.validationMessage ||
                  'Enter a valid move in year.',
              ]
            : []
        "
      />
    </v-col>
    <template v-for="option in residenceTypeOptions" :key="option.value">
      <template v-if="formValues[getFieldKey('residenceTypeCode')] === option.value">
        <v-col cols="12" lg="4" v-if="option.showPayment" class="py-0 pt-lg-3">
          <v-text-field
            v-model="formattedRentMortgagePaymentAmountInput"
            :label="buyerAddressSchema?.[getFieldKey('rentMortgagePaymentAmount')]?.label"
            @blur="handleRentMortgagePaymentAmount(formattedRentMortgagePaymentAmountInput, 'blur')"
            @focus="
              handleRentMortgagePaymentAmount(formattedRentMortgagePaymentAmountInput, 'focus')
            "
            @input="
              (event: InputEvent): void =>
                handleRentMortgagePaymentAmount((event.target as HTMLInputElement).value, 'input')
            "
            @keydown="onlyAllowNumbers"
            maxlength="14"
            type="tel"
            :error-messages="
              hasError(getFieldKey('rentMortgagePaymentAmount'))
                ? [
                    buyerAddressSchema?.[getFieldKey('rentMortgagePaymentAmount')]
                      ?.validationMessage || 'Enter a valid rent or mortgage payment amount',
                  ]
                : []
            "
            :data-testid="`sca-${getFieldKey('rentMortgagePaymentAmount')}`"
          />
        </v-col>
        <v-col
          cols="12"
          lg="4"
          v-if="option.showOther"
          class="py-0"
          :class="option.showPayment ? '' : 'pt-lg-3'"
        >
          <v-text-field
            v-model="localFormValues[getFieldKey('otherDetails')]"
            :data-testid="`sca-${getFieldKey('otherDetails')}`"
            label="Other Details"
          />
        </v-col>
      </template>
    </template>
  </v-row>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions } from 'pinia';
import { monthOptions } from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import { FieldsPropType } from '@lib/FieldTypes';
import {
  onlyAllowNumbers,
  handlePaste,
  formatCurrencyBlur,
  formatCurrencyFocus,
  formatCurrencyInput,
} from '@util/commonUtils';

export default defineComponent({
  name: 'BuyerAddressDetails',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    fieldsMap: {
      type: Map as PropType<Map<String, any>>,
      default: () => ({}),
    },
    isCobuyer: {
      type: Boolean,
      default: false,
    },
    formValues: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    buyerAddressSchema: {
      type: Object as PropType<Record<string, any> | undefined>,
      required: true,
    },
    stateOptions: {
      type: Array as PropType<string[]>,
      required: true,
    },
    isPrevious: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Function as PropType<(key: string) => boolean>,
      required: true,
    },
    residenceTypeOptions: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    dataTestIdSuffixes: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      yearOptions: Array.from({ length: 51 }, (_, i) => new Date().getFullYear() - i),
    };
  },
  computed: {
    residenceTypeCodeSelected() {
      const code = this.isPrevious
        ? this.formValues.previousResidenceTypeCode
        : this.formValues.residenceTypeCode;
      return Boolean(code);
    },
    coApplicantSameAddress(): boolean {
      return this.formValues?.coApplicantSameAddress || false;
    },
    monthOptions() {
      return monthOptions;
    },
    validMonthOptions() {
      const moveInYear = this.formValues[this.getFieldKey('moveInYear')];
      if (moveInYear === new Date().getFullYear()) {
        return this.monthOptions.slice(0, new Date().getMonth() + 1);
      }
      return this.monthOptions;
    },
    validYearOptions() {
      const moveInMonth = this.formValues[this.getFieldKey('moveInMonth')];
      if (moveInMonth > new Date().getMonth() + 1) {
        return this.yearOptions.slice(1);
      }
      return this.yearOptions;
    },
    localFormValues: {
      get() {
        return this.formValues;
      },
      set(newValues) {
        this.$emit('update:formValues', newValues);
      },
    },
    coPrefix() {
      return this.isCobuyer ? 'co-applicant-' : '';
    },
    formattedRentMortgagePaymentAmountInput: {
      get(): string {
        return this.formatCurrencyInput(
          this.localFormValues[this.getFieldKey('rentMortgagePaymentAmountString')]
        );
      },
      set(value: string) {
        this.localFormValues[this.getFieldKey('rentMortgagePaymentAmountString')] =
          this.formatCurrencyInput(value);
      },
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['formattedAddress']),
    onlyAllowNumbers,
    handlePaste,
    formatCurrencyBlur,
    formatCurrencyFocus,
    formatCurrencyInput,
    getFieldKey(field) {
      const fieldStr = String(field);
      return this.isPrevious
        ? `previous${fieldStr.charAt(0).toUpperCase()}${fieldStr.slice(1)}`
        : String(field);
    },
    handleRentMortgagePaymentAmount(
      value: string,
      eventType: 'blur' | 'focus' | 'input' = 'input'
    ) {
      this.localFormValues[this.getFieldKey('rentMortgagePaymentAmountString')] =
        eventType === 'blur'
          ? this.formatCurrencyBlur(value)
          : eventType === 'focus'
            ? this.formatCurrencyFocus(value)
            : this.formatCurrencyInput(value);
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-residence-label {
  padding-top: $spacing_stack-xxs;
  padding-bottom: 0;

  .sca-co-applicant-personal-details & {
    padding-top: 0;
  }
}
.move-in-month,
.move-in-year {
  padding-bottom: 0;

  .sca-co-applicant-personal-details & {
    padding-bottom: 10px;
  }
}
.same-address-details {
  padding-top: 0;
  padding-bottom: $spacing_stack-xxs;

  .small {
    margin-top: $spacing_stack-nano;
    color: $park-gray-20;
  }
}
</style>
