import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "6",
          xl: "4",
          class: "py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.identityValues.firstName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.identityValues.firstName) = $event)),
              label: _ctx.buyerIdentitySchema?.firstName?.label,
              "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.firstName}`,
              "error-messages": 
          _ctx.hasError('firstName') ? _ctx.buyerIdentitySchema?.firstName?.validationMessage : []
        
            }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "6",
          xl: "4",
          class: "py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.identityValues.middleName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.identityValues.middleName) = $event)),
              label: _ctx.buyerIdentitySchema?.middleName?.label,
              "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.middleName}`,
              "error-messages": 
          _ctx.hasError('middleName') ? _ctx.buyerIdentitySchema?.middleName?.validationMessage : []
        
            }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "6",
          xl: "4",
          class: "py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.identityValues.lastName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.identityValues.lastName) = $event)),
              "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.lastName}`,
              label: _ctx.buyerIdentitySchema?.lastName?.label,
              "error-messages": 
          _ctx.hasError('lastName') ? _ctx.buyerIdentitySchema?.lastName?.validationMessage : []
        
            }, null, 8, ["modelValue", "data-testid", "label", "error-messages"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          xl: "4",
          class: "buyerNameForm py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              modelValue: _ctx.identityValues.suffix,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.identityValues.suffix) = $event)),
              label: _ctx.buyerIdentitySchema?.suffix?.label,
              "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-suffix`,
              items: _ctx.suffixOptionMapper,
              "item-title": "text",
              "item-value": "value"
            }, null, 8, ["modelValue", "label", "data-testid", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          xl: "4",
          class: "buyerNameForm py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.formattedDOB,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formattedDOB) = $event)),
              onInput: _ctx.handleDOBInput,
              onKeydown: _ctx.onlyAllowNumbers,
              onPaste: _withModifiers(_ctx.handlePaste, ["prevent"]),
              label: _ctx.buyerIdentitySchema?.dateOfBirth?.label,
              "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.dateOfBirth}`,
              type: "tel",
              maxlength: "10",
              "error-messages": 
          _ctx.hasError('dateOfBirth') ? _ctx.buyerIdentitySchema?.dateOfBirth?.validationMessage : []
        
            }, null, 8, ["modelValue", "onInput", "onKeydown", "onPaste", "label", "data-testid", "error-messages"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "6",
          class: "py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.contactInfoValues.email,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contactInfoValues.email) = $event)),
              label: _ctx.buyerContactSchema?.email?.label,
              "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.email}`,
              type: "email",
              "error-messages": _ctx.hasError('email') ? _ctx.buyerContactSchema?.email?.validationMessage : []
            }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "6",
          class: "py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.formattedPhone,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formattedPhone) = $event)),
              onInput: _ctx.handlePhoneInput,
              onKeydown: _ctx.onlyAllowNumbers,
              onPaste: _withModifiers(_ctx.handlePaste, ["prevent"]),
              label: _ctx.buyerContactSchema?.phone?.label,
              "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.phone}`,
              type: "tel",
              maxlength: "12",
              "error-messages": _ctx.hasError('phone') ? _ctx.buyerContactSchema?.phone?.validationMessage : []
            }, null, 8, ["modelValue", "onInput", "onKeydown", "onPaste", "label", "data-testid", "error-messages"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}