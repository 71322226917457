import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "h6" }
const _hoisted_2 = { class: "h6" }
const _hoisted_3 = {
  key: 0,
  href: "#",
  class: "remove-co-applicant",
  "data-testid": "sca-remove-co-applicant"
}
const _hoisted_4 = { class: "link-text" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "agreement-checkbox" }
const _hoisted_8 = { class: "disclaimer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_ApplicantDetails = _resolveComponent("ApplicantDetails")!
  const _component_panel_section = _resolveComponent("panel-section")!
  const _component_Store = _resolveComponent("Store")!
  const _component_Employment = _resolveComponent("Employment")!
  const _component_AdditionalDetails = _resolveComponent("AdditionalDetails")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: _normalizeClass(['sca-submit', { 'is-submitting': _ctx.isLoading }])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_expansion_panels, { multiple: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel, { value: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_expansion_panel_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.getLabel('primary-applicant-header')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_expansion_panel_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_section, {
                    labelKey: "personal-details-label",
                    enableEdit: _ctx.enableEdit
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ApplicantDetails, {
                        buyer: _ctx.primaryBuyer,
                        formattedAddress: _ctx.formattedAddress(false, false),
                        previousFormattedAddress: _ctx.formattedAddress(true, false),
                        formattedMonthAndYear: _ctx.formattedMonthAndYear(false, false, 'address'),
                        previousFormattedMonthAndYear: _ctx.formattedMonthAndYear(true, false, 'address')
                      }, null, 8, ["buyer", "formattedAddress", "previousFormattedAddress", "formattedMonthAndYear", "previousFormattedMonthAndYear"])
                    ]),
                    _: 1
                  }, 8, ["enableEdit"]),
                  _createVNode(_component_panel_section, {
                    labelKey: "store-label",
                    enableEdit: _ctx.enableEdit
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Store)
                    ]),
                    _: 1
                  }, 8, ["enableEdit"]),
                  _createVNode(_component_panel_section, {
                    labelKey: "employment-label",
                    enableEdit: _ctx.enableEdit
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Employment, {
                        buyer: _ctx.primaryBuyer,
                        formattedMonthAndYear: _ctx.formattedMonthAndYear(false, false, 'employment'),
                        previousFormattedMonthAndYear: _ctx.formattedMonthAndYear(true, false, 'employment')
                      }, null, 8, ["buyer", "formattedMonthAndYear", "previousFormattedMonthAndYear"])
                    ]),
                    _: 1
                  }, 8, ["enableEdit"]),
                  _createVNode(_component_panel_section, {
                    labelKey: "additional-info-label",
                    enableEdit: _ctx.enableEdit
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AdditionalDetails, { additionalDetails: _ctx.additionalDetailsValues }, null, 8, ["additionalDetails"])
                    ]),
                    _: 1
                  }, 8, ["enableEdit"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.flow === 'coApplicant')
            ? (_openBlock(), _createBlock(_component_v_expansion_panel, {
                key: 0,
                value: "co-applicant"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel_title, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('co-applicant-header')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_expansion_panel_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_panel_section, {
                        labelKey: "co-applicant-details-label",
                        enableEdit: _ctx.enableEdit
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ApplicantDetails, {
                            buyer: _ctx.coBuyer,
                            isCobuyer: true,
                            formattedAddress: _ctx.formattedAddress(false, true),
                            previousFormattedAddress: _ctx.formattedAddress(true, true),
                            formattedMonthAndYear: _ctx.formattedMonthAndYear(false, true, 'address'),
                            previousFormattedMonthAndYear: _ctx.formattedMonthAndYear(true, true, 'address')
                          }, null, 8, ["buyer", "formattedAddress", "previousFormattedAddress", "formattedMonthAndYear", "previousFormattedMonthAndYear"])
                        ]),
                        _: 1
                      }, 8, ["enableEdit"]),
                      _createVNode(_component_panel_section, {
                        labelKey: "co-applicant-employment-label",
                        enableEdit: _ctx.enableEdit
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Employment, {
                            buyer: _ctx.coBuyer,
                            isCobuyer: true,
                            formattedMonthAndYear: _ctx.formattedMonthAndYear(false, true, 'employment'),
                            previousFormattedMonthAndYear: _ctx.formattedMonthAndYear(true, true, 'employment')
                          }, null, 8, ["buyer", "formattedMonthAndYear", "previousFormattedMonthAndYear"])
                        ]),
                        _: 1
                      }, 8, ["enableEdit"]),
                      (false /* Remove co-applicant is not in the initial release */)
                        ? (_openBlock(), _createElementBlock("a", _hoisted_3, [
                            _createVNode(_component_v_icon, { icon: "ep-icon-delete" }),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('remove-co-app-label')), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_form, {
        ref: "form",
        onSubmit: _withModifiers(_ctx.submitForm, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  (_ctx.fields?.['form-title']?.value)
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.fields?.['title']?.value), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_sc_rich_text, {
                      field: _ctx.fieldsMap.get('confirmation-message.fieldNotes'),
                      "data-testid": "sca-confirmation-message-notes"
                    }, null, 8, ["field"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_v_checkbox, {
                      modelValue: _ctx.authorized,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.authorized) = $event)),
                      "data-testid": "sca-authorization-checkbox"
                    }, {
                      label: _withCtx(() => [
                        _createVNode(_component_sc_rich_text, {
                          field: _ctx.fieldsMap.get('authorization-legal.authorization')
                        }, null, 8, ["field"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_sc_rich_text, {
                      field: _ctx.fieldsMap.get('authorization-legal.legal'),
                      "data-testid": "sca-authorization-disclaimer"
                    }, null, 8, ["field"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["class"]))
}