import * as Yup from 'yup';

/**
 * The available flow options for the application.
 * - 'single': A single applicant flow.
 * - 'coApplicant': A co-applicant flow.
 */
export type FlowOption = 'single' | 'coApplicant';

export const FlowOptionTypes = {
  SINGLE: 'single' as FlowOption,
  CO_APPLICANT: 'coApplicant' as FlowOption,
} as const;

/**
 * Represents a Yup schema for validating a single form (e.g., the "buyer" form).
 */
export type ValidationSchema = Yup.ObjectSchema<any>;

/**
 * Represents a collection of Yup schemas, where each key corresponds to a form name
 * (e.g., "buyerName", "address") and the value is its respective validation schema.
 */
export type ValidationSchemas = Record<string, Yup.ObjectSchema<any>>;

/**
 * The result of a Yup schema validation.
 * - `null` indicates no validation errors.
 * - A record object contains field names and corresponding error messages.
 */
export type ValidationResult = Record<string, string> | null;

/**
 * Represents the schema definitions for the forms combined from Route One and Sitecore.
 * Each form has a set of fields, and each field has its own definition.
 */
export type SchemaDefinitions = Record<string, Record<string, SchemaFieldDefinition>>;

/**
 * Represents multiple Route One schema definitions (e.g., 'buyer', 'address').
 */
export type AbstractSchemaDefinitions = Record<string, AbstractSchemaDefinition>;

/**
 * Defines the structure of a field within the final schema object.
 * - `label`: The display name of the field.
 * - `type`: The data type of the field (e.g., 'string', 'number', 'boolean').
 * - `required`: Whether the field is required for form submission.
 * - `format`: An optional format for the field (e.g., 'email', 'date').
 * - `pattern`: A regex pattern that the field value must match.
 * - `rules`: Additional validation rules (e.g., minimum and maximum values).
 */
export interface SchemaFieldDefinition {
  label?: string;
  type: string;
  required?: boolean;
  format?: string;
  pattern?: string;
  validationMessage?: string;
  $ref?: string;
  rules?: {
    minimum?: number;
    maximum?: number;
    pattern?: string;
  };
  minLength?: number;
  maxLength?: number;
}

/**
 * Represents how a field is defined in the Route One schema.
 * - `type`: The data type of the field.
 * - `description`: An optional description of the field.
 * - `enum`: A list of allowed values for the field.
 * - `properties`: Nested fields (if the field is an object).
 * - `pattern`: A regex pattern the field must match.
 * - `required`: A list of required nested fields.
 */
export interface AbstractSchemaField {
  type?: string;
  description?: string;
  format?: string;
  enum?: string[];
  properties?: Record<string, AbstractSchemaField>;
  pattern?: string;
  $ref?: string;
  required?: string[];
  minLength?: number;
  maxLength?: number;
}

/**
 * Represents a complete Route One schema definition (e.g., 'buyer', 'address').
 * - `properties`: Fields within the schema.
 * - `required`: A list of required fields.
 */
export interface AbstractSchemaDefinition {
  properties: Record<string, AbstractSchemaField>;
  required?: string[];
  $defs?: Record<string, Record<string, any>>;
}

/**
 * Represents a field definition in the Sitecore schema.
 * - `FieldName`: The name of the field.
 * - `Label`: The display name of the field.
 * - `Required`: Whether the field is required.
 * - `ValidationMessage`: A custom validation message.
 */
export interface SitecoreField {
  FieldName: { value: string };
  Label?: { value: string };
  Required?: { value: boolean };
  ValidationMessage?: { value: string };
  FormName?: { value: string }; // For fields like 'buyer-form'
  [key: string]: any; // Allow additional fields
}

/**
 * Represents the Sitecore schema.
 * - `fields`: Contains an array of Sitecore schema items.
 */
export interface SitecoreSchema {
  fields: {
    children: SitecoreSchemaItem[];
  };
}

/**
 * Represents an item within the Sitecore schema.
 * - `fields`: The main field definitions.
 */
export interface SitecoreSchemaItem {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: SitecoreField; // The main fields object
  [key: string]: any; // Allow extra properties
}

/**
 * Represents the structure of the entire credit application.
 */
export interface CreditApplication {
  routeOneDealerId: string;
  testPropNotNeeded?: string;
  primaryBuyer: Buyer;
  coBuyer?: Buyer;
  dealJacketVehicles: DealJacketVehicle[];
  finance?: Finance;
  additionalDetails: AdditionalDetails;
}

/**
 * Represents the handler function for form submission.
 */
export interface FormComponentType {
  submitHandler: () => Promise<Record<string, string>>;
}

/**
 * Navigation structure for the credit application.
 * Each path corresponds to an array of flow types (e.g., 'single' or 'coApplicant') that the path belongs to.
 */
export interface CreditAppNav {
  [path: string]: string[];
}

/**
 * Contains details about the current step in the application.
 * - `componentName`: The name of the Vue component for the current step.
 * - `formTitle`: An optional title for the form being displayed on this step.
 * - `nextStep`: An optional path to the next step in the flow.
 */
export interface CurrentStepDetails {
  componentName: string;
  formTitle?: string;
  nextStep?: string;
}

/* Route one Customer Type code- preset */
export const CustomerTypeCodes = {
  INDIVIDUAL: 'D',
} as const;

export type CustomerTypeCode = (typeof CustomerTypeCodes)[keyof typeof CustomerTypeCodes];

/**
 * Represents a buyer's details, including identity, contact information, and address.
 */
export interface Buyer {
  customerTypeCode: CustomerTypeCode | undefined;
  identity: BuyerIdentity;
  contactInfo: ContactInfo;
  personalDetails?: PersonalDetails;
  address: Address;
  employmentDetails?: EmploymentDetails;
}

/**
 * Represents the identity information of a buyer.
 */
export interface BuyerIdentity {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: SuffixType;
  displayName: string;
  dateOfBirth: string;
  dateOfBirthDefined: boolean;
}

/**
 * Represents a buyer's contact information.
 */
export interface ContactInfo {
  phone: string;
  phoneType: PhoneType;
  email: string;
  altPhone?: string;
  altPhoneType?: PhoneType;
}

/**
 * Represents additional details.
 */
export interface AdditionalDetails {
  storeId: string;
  source?: string;
  comments?: {
    GClid?: string;
    FBClids?: string;
    MSClids?: string;
    GAClientID?: string;
    timestamp?: string;
    nearestLocation?: string;
    zipCode?: string;
    notes?: string;
    trade?: any;
  }[];
}

/**
 * Represents a buyer's personal details.
 */
export interface PersonalDetails {
  ssn?: string;
  ssnDefined?: boolean;
}

/**
 * Represents a buyer's address information.
 */
export interface Address {
  addressLine1: string;
  addressLine2?: string;
  zipCode: string;
  city: string;
  state: string | undefined;
  county?: string;
  countryCode: CountryCode;
  moveInMonth?: number;
  moveInYear?: number;
  monthsAtAddress: number;
  residenceTypeCode: string;
  residenceTypeCodeText?: string;
  rentMortgagePaymentAmountString?: string;
  rentMortgagePaymentAmount?: number;
  previousAddressLine1?: string;
  previousAddressLine2?: string;
  previousZipCode?: string;
  previousCity?: string;
  previousState?: string;
  previousCounty?: string;
  previousCountryCode?: CountryCode;
  previousMoveInMonth?: number;
  previousMoveInYear?: number;
  previousMonthsAtAddress?: number;
  previousResidenceTypeCode?: string;
  previousResidenceTypeCodeText?: string;
  previousRentMortgagePaymentAmountString?: string;
  previousRentMortgagePaymentAmount?: number;
  coApplicantSameAddress?: boolean;
}

/**
 * Represents a buyer's employment and income details.
 */
export interface EmploymentDetails {
  employmentTypeCode?: string;
  employmentStatusCode?: string;
  employmentTitle?: string;
  employerName?: string;
  employerPhone?: string;
  employerStartMonth?: number; // Not on schema
  employerStartYear?: number; // Not on schema
  monthsOnJob?: number;

  incomeTypeCode?: IncomeType; // not on schema
  hourlyPayRate?: number; // not on schema
  hourlyPayRateString?: string; // not on schema
  avgWeeklyHours?: number; // not on schema
  avgWeeklyHoursString?: string; // not on schema
  hasBonusPay?: YesNo; // not on schema
  monthlyBonusPay?: number; // not on schema
  monthlyBonusPayString?: string; // not on schema
  annualOrMonthly?: IncomeFrequencyType; // not on schema
  annualIncome?: number; // not on schema
  annualIncomeString?: string; // not on schema
  monthlyIncome?: number; // not on schema
  monthlyIncomeString?: string | ''; // not on schema

  incomeAmount?: number;
  incomeIntervalCode?: IncomeIntervalCode;
  currencyTypeCode?: CurrencyCode;
  hasExtraIncome?: YesNo;

  otherIncomeSourceCode?: string;
  otherIncomeSourceDescription?: string;
  otherIncomeAmount?: number;
  otherIncomeAmountString?: string;
  otherIncomeIntervalCode?: IncomeIntervalCode;
  otherCurrencyTypeCode?: CurrencyCode;
  educationLevelCode?: string;

  previousEmploymentTypeCode?: string; // Not on schema
  previousEmploymentStatusCode?: string; // Not on schema
  previousEmploymentTitle?: string; // Not on schema
  previousEmployerName?: string; // Not on schema
  previousEmployerPhone?: string; // Not on schema
  previousMonthsOnJob?: number; // Not on schema
  previousMonthsOnJobString?: string; // Not on schema

  previousIncomeTypeCode?: IncomeType; // not on schema
  previousHourlyPayRate?: number; // not on schema
  previousHourlyPayRateString?: string; // not on schema
  previousAvgWeeklyHours?: number; // not on schema
  previousAvgWeeklyHoursString?: string; // not on schema
  previousHasBonusPay?: YesNo; // not on schema
  previousMonthlyBonusPay?: number; // not on schema
  previousMonthlyBonusPayString?: string; // not on schema
  previousAnnualOrMonthly?: IncomeFrequencyType; // not on schema
  previousAnnualIncome?: number; // not on schema
  previousAnnualIncomeString?: string; // not on schema
  previousMonthlyIncome?: number; // not on schema
  previousMonthlyIncomeString?: string | ''; // not on schema

  previousIncomeAmount?: number; // Not on schema
  previousIncomeIntervalCode?: IncomeIntervalCode; // Not on schema
  previousCurrencyTypeCode?: CurrencyCode; // Not on schema
  previousEducationLevelCode?: string; // Not on schema
}

/**
 * Represents a vehicle within the deal jacket.
 */
export interface DealJacketVehicle {
  vehicle: Vehicle;
  intendedUse:
    | 'BUSINESS'
    | 'AGRICULTURAL'
    | 'HAZARDOUS'
    | 'LOCAL'
    | 'INTERSTATE'
    | 'INTERMEDIATE'
    | 'PERSONAL';
  tradeInVehicleIndicator?: boolean;
}

/**
 * Represents a vehicle's details.
 */
export interface Vehicle {
  vin: string;
  make?: string;
  model?: string;
  year?: number;
  style?: string;
  color?: string;
  condition?: 'NEW' | 'USED' | 'CERTIFIED_USED' | 'AUCTION' | 'DEMO';
  stockNumber?: string;
  collateralType?: 'AUTO' | 'LIGHT_TRUCK' | 'MEDIUM_TRUCK' | 'CONVERSION_VAN' | 'OTHER';
  mileage?: number;
  msrp?: number;
  wholesaleAmount?: number;
}

/**
 * Represents the financial details of a credit application.
 */
export interface Finance {
  transactionType: 'RETAIL' | 'LEASE' | 'BALLOON' | 'CASH' | 'PAYMENT_CALL';
  vehicleCashPrice?: number;
  cashDownPayment?: number;
  cashDownPaymentString?: string;
}

export type MonthOption = { text: string; value: number | string };

export const monthOptions: MonthOption[] = [
  { text: 'January', value: 1 },
  { text: 'February', value: 2 },
  { text: 'March', value: 3 },
  { text: 'April', value: 4 },
  { text: 'May', value: 5 },
  { text: 'June', value: 6 },
  { text: 'July', value: 7 },
  { text: 'August', value: 8 },
  { text: 'September', value: 9 },
  { text: 'October', value: 10 },
  { text: 'November', value: 11 },
  { text: 'December', value: 12 },
];

export type StateCode =
  | 'AL'
  | 'AK'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY'
  | 'AA'
  | 'AE'
  | 'AP'; // Military Addresses

export const stateOptions: StateCode[] = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'AA',
  'AE',
  'AP',
];

/**
 * Represents the available residence type options.
 * Determines if additional fields (like payment details or "Other" specification) should be displayed.
 */
export interface ResidenceTypeOption {
  text: string;
  value: string;
  showPayment: boolean;
  showOther: boolean;
}

/* 
  These represent valid residence type codes from the API.
  Each key is a numeric string representing a specific residence type.

  Option 5 is not currently in use of 3/20/25
*/
export const ResidenceTypeCodes = {
  OWNS_HOME_OUTRIGHT: '1',
  BUYING_HOME: '2',
  LIVING_WITH_RELATIVES: '3',
  RENTING_LEASING: '4',
  // OWNS_BUYING_MOBILE_HOME: '5',
  UNKNOWN: '6',
} as const;

/* 
  Represents the valid residence type codes.
  - Can be any value from the `ResidenceTypeCodes` object.
  - Ensures only valid residence types are used throughout the application.
*/
export type ResidenceTypeCode = (typeof ResidenceTypeCodes)[keyof typeof ResidenceTypeCodes];

/**
 * Maps residence type codes to their corresponding display configurations.
 * Controls which fields should be visible based on the selected residence type.
 */
export const residenceConfig: Record<ResidenceTypeCode, ResidenceConfig> = {
  [ResidenceTypeCodes.OWNS_HOME_OUTRIGHT]: { showPayment: false, showOther: false },
  [ResidenceTypeCodes.BUYING_HOME]: { showPayment: true, showOther: false },
  [ResidenceTypeCodes.LIVING_WITH_RELATIVES]: { showPayment: true, showOther: false },
  [ResidenceTypeCodes.RENTING_LEASING]: { showPayment: true, showOther: false },
  [ResidenceTypeCodes.UNKNOWN]: { showPayment: true, showOther: false },
};

/**
 * Represents the UI configuration for different residence types.
 */
export interface ResidenceConfig {
  showPayment: boolean;
  showOther: boolean;
}

/**
 * Defines the configuration for an employment type.
 * Determines which fields should be shown for different employment statuses.
 */
export interface EmploymentConfig {
  showEmployerName: boolean;
  showEmployerPhone: boolean;
  showEmploymentTitle: boolean;
  showIncome: boolean;
}

/**
 * Helper function to create an employment configuration.
 * This function is used to maintain consistent configuration settings
 * for different employment types.
 *
 * @param showEmployerName - Should the employer name field be displayed?
 * @param showEmployerPhone - Should the employer phone field be displayed?
 * @param showEmploymentTitle - Should the job title field be displayed?
 * @param showIncome - Should income details be displayed?
 * @returns An `EmploymentConfig` object with the specified settings.
 */
const employmentConfigOptions = (
  showEmployerName: boolean,
  showEmployerPhone: boolean,
  showEmploymentTitle: boolean,
  showIncome: boolean
): EmploymentConfig => ({
  showEmployerName,
  showEmployerPhone,
  showEmploymentTitle,
  showIncome,
});

/* 
  Current UI mapping is:
  (UI Left, API right)

  Employed: Full Time
  Military: Military
  Retired: Retired
  Unemployed: Not Applicable
  Other: Self-Employed

  Additional mapping:
  Employed + (Hourly && < 32 hours) : Part Time
  */

/* Below defines a central location to
   manage API employment statuses
 
 Part time, contract, seasonal, and temporary
 are not implemented by the UI as of 3/6/2025 NP
 */
export const EmploymentStatuses = {
  FULL_TIME: 'Full Time',
  MILITARY: 'Military',
  RETIRED: 'Retired',
  NOT_APPLICABLE: 'Not Applicable',
  SELF_EMPLOYED: 'Self-Employed',
  PART_TIME: 'Part Time',
  // CONTRACT: 'Contract',
  // SEASONAL: 'Seasonal',
  // TEMPORARY: 'Temporary',
} as const;

export type EmploymentStatus = (typeof EmploymentStatuses)[keyof typeof EmploymentStatuses];

/**
 * Maps employment statuses to their corresponding display configurations.
 * Controls which fields should be visible based on the selected employment status.
 *
 * Employment statuses mapped:
 * - Full Time
 * - Part Time
 * - Military
 * - Retired
 * - Not Applicable (Unemployed)
 * - Self-Employed
 */
export const employmentConfig: Record<EmploymentStatus, EmploymentConfig> = {
  [EmploymentStatuses.FULL_TIME]: employmentConfigOptions(true, true, true, true),
  [EmploymentStatuses.PART_TIME]: employmentConfigOptions(true, true, true, true),
  [EmploymentStatuses.MILITARY]: employmentConfigOptions(false, true, true, true),
  [EmploymentStatuses.RETIRED]: employmentConfigOptions(false, false, false, true),
  [EmploymentStatuses.NOT_APPLICABLE]: employmentConfigOptions(false, false, false, true),
  [EmploymentStatuses.SELF_EMPLOYED]: employmentConfigOptions(true, true, true, true),
};

/* 
  Route One API Suffixes- used for UI
  These suffixes represent common name extensions (e.g., "Jr.", "Sr.", "III").
  The values correspond to the accepted suffixes in Route One.

  Left is API Value, Right is UI value
  (None is converted to '' on submit of page)
*/
export const Suffixes = {
  None: 'None', // explicitly label the empty option
  JR: 'Jr.',
  SR: 'Sr.',
  // I: 'I', // Not currently used
  II: 'II',
  III: 'III',
  IV: 'IV',
  V: 'V',
} as const;

/* 
  Represents the valid types for name suffixes.
  - Can be any key from the `Suffixes` object.
  - Can also be 'None' (explicitly representing no suffix) or an empty string.
  - Allows `undefined` to support optional suffix values.
*/
export type SuffixType = keyof typeof Suffixes | '' | undefined;

/* 
  Route One API Phone Types
  These phone type values correspond to the accepted categories for phone numbers in Route One.
*/
export const PhoneTypes = {
  HOME: 'HOME',
  WORK: 'WORK',
  MOBILE: 'MOBILE',
} as const;

/* 
  Represents the valid types for phone numbers.
  - Can be any value from the `PhoneTypes` object.
  - Ensures only valid phone types are used throughout the application.
*/
export type PhoneType = (typeof PhoneTypes)[keyof typeof PhoneTypes];

/* 
  These represent Figma options 'Hourly' or 'Salary'
  Not used by API, but used to make validation decisions
*/
export const IncomeTypes = {
  HOURLY: 'Hourly',
  SALARY: 'Salary',
} as const;

/* 
  Represents the valid types for income.
  - Can be any value from the `IncomeTypes` object.
  - Ensures only valid income types are used throughout the application.
*/
export type IncomeType = (typeof IncomeTypes)[keyof typeof IncomeTypes] | '';

/* 
  These represent Figma options 'Annual' or 'Monthly'
  Not used by API, but used to make validation decisions
*/
export const IncomeFrequency = {
  ANNUAL: 'Annual',
  MONTHLY: 'Monthly',
} as const;

/* 
  Represents the valid types for income frequency.
  - Can be any value from the `IncomeFrequency` object.
  - Ensures only valid income frequency types are used throughout the application.
*/
export type IncomeFrequencyType = (typeof IncomeFrequency)[keyof typeof IncomeFrequency] | '';

export const IncomeIntervalCodes = {
  WEEKLY: 'WK',
  BIWEEKLY: 'BiWK',
  SEMIMONTHLY: 'SmMO',
  MONTHLY: 'MO',
  YEARLY: 'YR',
} as const;

/**
 * Represents the valid income interval codes as values.
 */
export type IncomeIntervalCode =
  | (typeof IncomeIntervalCodes)[keyof typeof IncomeIntervalCodes]
  | '';

/**
 * Represents the valid country codes.
 * These codes indicate the supported countries.
 * - 'US' = United States
 * - 'CA' = Canada
 * - ''   = No value selected
 */
export const CountryCodes = {
  UNITED_STATES: 'US',
  CANADA: 'CA',
} as const;

/**
 * Represents the valid country code values.
 * Ensures only valid country codes are used throughout the application.
 */
export type CountryCode = (typeof CountryCodes)[keyof typeof CountryCodes] | '';

export const CurrencyCodes = {
  USD: 'USD',
  CAD: 'CAD',
} as const;

/**
 * Represents the valid currency codes.
 */
export type CurrencyCode = (typeof CurrencyCodes)[keyof typeof CurrencyCodes] | '';

export const YesNoOptions = {
  YES: 'Yes',
  NO: 'No',
} as const;

/**
 * Represents a Yes/No option, including an empty string for cases where it's not set.
 */
export type YesNo = (typeof YesNoOptions)[keyof typeof YesNoOptions] | '';
