import axios from 'axios';
import { default as appConfig } from '@/appConfig';
import { CapiModel, CapiUser } from '@/types/Analytics';

const LOCAL_HOST = appConfig.sitecoreApiHost;
const client = axios.create({
  baseURL: `${LOCAL_HOST}api`,
});

function sendCapiEvent(path: string, payload) {
  client
    .post(path, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((_response) => {})
    .catch((error) => {
      console.error('Error sending event: ', error);
    });
}

export function sendTikTokCapiEvent(capiModel: CapiModel, tiktokEventName: string) {
  if (tiktokEventName) capiModel.eventName = tiktokEventName;
  sendCapiEvent('/capi/tiktok', capiModel);
}

export function sendFacebookCapiEvent(capiModel: CapiModel) {
  sendCapiEvent('/capi/fb', capiModel);
}

export function generateCapiEventId(eventName: string) {
  const id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
  const eventid = `${eventName}-${id}-${Date.now()}`;
  return eventid;
}

export function getGclidFromCookie() {
  return getValueFromCookie('gclid');
}

export function getMsclidFromCookie() {
  return getValueFromCookie('msclid');
}

export function getGaFromCookie() {
  return getValueFromCookie('_ga');
}

export function getFbclidFromCookie() {
  return getValueFromCookie('fbclid');
}

export function getFpbFromCookie() {
  const result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
  if (!(result && result[1])) {
    return null;
  }
  return result[1];
}

export function getValueFromCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp(`(?:^|;\\s*)${name}=([^;]*)`));
  return match ? decodeURIComponent(match[1]) : null;
}

export function getFbClidFromUrl() {
  const params = new URLSearchParams(window.location.search);

  if (params.has('fbclid')) return params.get('fbclid');

  return null;
}

export function getCookie(name) {
  const dc = document.cookie;
  const prefix = name + '=';
  let begin = dc.indexOf('; ' + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
  }
  let end = document.cookie.indexOf(';', begin);
  if (end == -1) {
    end = dc.length;
  }
  return decodeURI(dc.substring(begin + prefix.length, end));
}

export function getQueryParam(q) {
  const params = new URLSearchParams(window.location.search);
  if (params.has(q)) {
    return params.get(q);
  }
  return null;
}
export function generateCapiModelSrp(
  eventName,
  contentName,
  capiEventId,
  requestUrl,
  baseUrl,
  vin,
  userData,
  contentCategory,
  value,
  search,
  fbp,
  fbclid
): CapiModel {
  const capiModel: CapiModel = {
    eventName: eventName,
    contentName: contentName,
    eventId: capiEventId,
    requestUrl: requestUrl,
    baseUrl: baseUrl,
    vin: vin,
    userData: userData,
    contentCategory: contentCategory,
    value: value,
    search: search,
    fbp: fbp,
    fbclid: fbclid,
  };

  return capiModel;
}

export function generateUserDataSrp(
  firstName,
  lastName,
  street,
  city,
  state,
  zipCode,
  email,
  phone,
  userAgent
): CapiUser {
  const userData: CapiUser = {
    firstName: firstName,
    lastName: lastName,
    street: street,
    city: city,
    state: state,
    zipCode: zipCode,
    email: email,
    phone: phone,
    clientUserAgent: userAgent,
  };
  return userData;
}

function initializeDocument() {
  const FB_COOKIE = 'fbclid';
  const GC_COOKIE = 'gclid';

  const f = getCookie(FB_COOKIE);
  const g = getCookie(GC_COOKIE);

  const fq = getQueryParam(FB_COOKIE);
  const gq = getQueryParam(GC_COOKIE);

  if (!f && !fq) {
    document.cookie = `${FB_COOKIE}=${fq}; path=/`;
  }
  if (!g && !gq) {
    document.cookie = `${GC_COOKIE}=${gq}; path=/`;
  }
}
if (typeof window !== 'undefined') {
  initializeDocument();
}
