import {
  EmploymentDetails,
  IncomeType,
  IncomeFrequencyType,
  IncomeIntervalCode,
  IncomeIntervalCodes,
  CurrencyCode,
  CurrencyCodes,
  YesNo,
  YesNoOptions,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';

/**
 * Fields related to current employment only.
 */
export type CurrentEmploymentForm = Pick<
  EmploymentDetails,
  | 'employmentTypeCode'
  | 'employmentStatusCode'
  | 'employmentTitle'
  | 'employerName'
  | 'employerPhone'
  | 'employerStartMonth'
  | 'employerStartYear'
  | 'monthsOnJob'
  | 'incomeTypeCode'
  | 'hourlyPayRate'
  | 'hourlyPayRateString'
  | 'avgWeeklyHours'
  | 'avgWeeklyHoursString'
  | 'hasBonusPay'
  | 'monthlyBonusPay'
  | 'monthlyBonusPayString'
  | 'annualOrMonthly'
  | 'annualIncome'
  | 'annualIncomeString'
  | 'monthlyIncome'
  | 'monthlyIncomeString'
  | 'incomeAmount'
  | 'incomeIntervalCode'
  | 'currencyTypeCode'
  | 'educationLevelCode'
>;

/**
 * Fields related to previous employment only.
 */
export type PreviousEmploymentForm = Pick<
  EmploymentDetails,
  | 'previousEmploymentTypeCode'
  | 'previousEmploymentStatusCode'
  | 'previousEmploymentTitle'
  | 'previousEmployerName'
  | 'previousEmployerPhone'
  | 'previousMonthsOnJob'
  | 'previousMonthsOnJobString'
  | 'previousIncomeTypeCode'
  | 'previousHourlyPayRate'
  | 'previousHourlyPayRateString'
  | 'previousAvgWeeklyHours'
  | 'previousAvgWeeklyHoursString'
  | 'previousHasBonusPay'
  | 'previousMonthlyBonusPay'
  | 'previousMonthlyBonusPayString'
  | 'previousAnnualOrMonthly'
  | 'previousAnnualIncome'
  | 'previousAnnualIncomeString'
  | 'previousMonthlyIncome'
  | 'previousMonthlyIncomeString'
  | 'previousIncomeAmount'
  | 'previousIncomeIntervalCode'
  | 'previousCurrencyTypeCode'
  | 'previousEducationLevelCode'
>;

/**
 * Fields related to other income (optional section).
 */
export type OtherIncomeForm = Pick<
  EmploymentDetails,
  | 'hasExtraIncome'
  | 'otherIncomeSourceCode'
  | 'otherIncomeSourceDescription'
  | 'otherIncomeAmount'
  | 'otherIncomeAmountString'
  | 'otherIncomeIntervalCode'
  | 'otherCurrencyTypeCode'
>;

// ------------------- Default Values & Helpers -------------------

/**
 * Default values for current employment form.
 */
export const defaultCurrentEmploymentValues: CurrentEmploymentForm = {
  employmentTypeCode: '',
  employmentStatusCode: '',
  employmentTitle: '',
  employerName: '',
  employerPhone: '',
  employerStartMonth: undefined,
  employerStartYear: undefined,
  monthsOnJob: 0,
  incomeTypeCode: '' as IncomeType,
  hourlyPayRate: 0,
  hourlyPayRateString: '',
  avgWeeklyHours: 0,
  avgWeeklyHoursString: '',
  hasBonusPay: YesNoOptions.NO as YesNo,
  monthlyBonusPay: 0,
  monthlyBonusPayString: '',
  annualOrMonthly: '' as IncomeFrequencyType,
  annualIncome: 0,
  annualIncomeString: '',
  monthlyIncome: 0,
  monthlyIncomeString: '',
  incomeAmount: 0,
  incomeIntervalCode: '' as IncomeIntervalCode,
  currencyTypeCode: '' as CurrencyCode,
  educationLevelCode: '',
};

/**
 * Returns a fresh copy of the default current employment form values.
 */
export function getDefaultCurrentEmployment(): CurrentEmploymentForm {
  return { ...defaultCurrentEmploymentValues };
}

/**
 * Default values for previous employment form.
 */
export const defaultPreviousEmploymentValues: PreviousEmploymentForm = {
  previousEmploymentTypeCode: '',
  previousEmploymentStatusCode: '',
  previousEmploymentTitle: '',
  previousEmployerName: '',
  previousEmployerPhone: '',
  previousMonthsOnJob: 0,
  previousMonthsOnJobString: '',
  previousIncomeTypeCode: '' as IncomeType,
  previousHourlyPayRate: 0,
  previousHourlyPayRateString: '',
  previousAvgWeeklyHours: 0,
  previousAvgWeeklyHoursString: '',
  previousHasBonusPay: YesNoOptions.NO as YesNo,
  previousMonthlyBonusPay: 0,
  previousMonthlyBonusPayString: '',
  previousAnnualOrMonthly: '' as IncomeFrequencyType,
  previousAnnualIncome: 0,
  previousAnnualIncomeString: '',
  previousMonthlyIncome: 0,
  previousMonthlyIncomeString: '',
  previousIncomeAmount: 0,
  previousIncomeIntervalCode: '' as IncomeIntervalCode,
  previousCurrencyTypeCode: CurrencyCodes.USD,
};

/**
 * Returns a fresh copy of the default previous employment form values.
 */
export function getDefaultPreviousEmployment(): PreviousEmploymentForm {
  return { ...defaultPreviousEmploymentValues };
}

/**
 * Default values for optional other income section.
 */
export const defaultOtherIncomeValues: OtherIncomeForm = {
  hasExtraIncome: YesNoOptions.NO as YesNo,
  otherIncomeSourceCode: '',
  otherIncomeSourceDescription: '',
  otherIncomeAmount: 0,
  otherIncomeAmountString: '',
  otherIncomeIntervalCode: IncomeIntervalCodes.MONTHLY,
  otherCurrencyTypeCode: CurrencyCodes.USD,
};

/**
 * Returns a fresh copy of the default other income form values.
 */
export function getDefaultOtherIncome(): OtherIncomeForm {
  return { ...defaultOtherIncomeValues };
}
