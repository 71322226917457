<template>
  <div
    :class="
      isPrevious ? 'previous-employment employment-status-section' : 'employment-status-section'
    "
  >
    <v-row>
      <v-col cols="12">
        <FormHeader :title="getEmploymentTitle">
          <template #tooltip v-if="isPrevious">
            <sc-rich-text
              :field="fieldsMap.get(coPrefix + 'employment.previous-job-tool-tip.Label')?.value"
              data-testid="previous-employer-icon--helperText"
            />
            <div class="tooltip-wrapper">
              <v-tooltip
                v-model="previousEmploymentTooltip"
                location="top"
                :open-on-hover="false"
                :text="fieldsMap.get(coPrefix + 'employment.previous-job-tool-tip.Label')?.value"
                class="top"
                width="210"
              >
                <template v-slot:activator="{ props }">
                  <sc-image
                    @click="previousEmploymentTooltip = !previousEmploymentTooltip"
                    @keydown.enter="previousEmploymentTooltip = !previousEmploymentTooltip"
                    class="tooltip-trigger"
                    :media="fieldsMap.get(coPrefix + 'employment.previous-job-tool-tip.icon-image')"
                    tabindex="0"
                    v-bind="props"
                    width="20"
                    height="20"
                  />
                </template>
              </v-tooltip>
            </div>
          </template>
        </FormHeader>
      </v-col>
      <v-col cols="12" class="toggle-buttons pb-4">
        <p class="logic-selector-label">
          {{ fieldsMap.get(coPrefix + 'employment.primary-job-income.Label')?.value }}
        </p>
        <v-btn-toggle v-model="selectedEmploymentStatusCode" class="logic-selector" mandatory>
          <v-btn
            v-for="(value, key, index) in isPrevious
              ? employmentStatusMapperPrevious
              : employmentStatusMapper"
            :key="key"
            :value="value"
            :disabled="selectedEmploymentStatusCode === value"
            :data-testid="getDataTestId(`employmentStatusCode-${index}`)"
            @click="handleToggle('employmentType', value)"
            >{{ key }}
          </v-btn>
        </v-btn-toggle>
        <!-- TODO: duplicate the out of the box styling here - v-btn-toggle doesn't provide it like other inputs -->
        <p
          v-if="errorMessages('employmentStatusCode', 'Select an employment status.').length"
          class="error-text"
        >
          {{ errorMessages('employmentStatusCode', 'Select an employment status.')[0] }}
        </p>
      </v-col>
    </v-row>
  </div>

  <div
    id="employed-section"
    class="employed-section mt-6"
    v-if="
      showEmployerName || showEmployerPhone || showEmploymentTitle || employmentStatusCodeSelected
    "
  >
    <v-row>
      <v-col cols="12" lg="6" v-if="showEmployerName" class="py-0">
        <v-text-field
          v-model="localEmploymentDetails[getFieldKey('employerName')]"
          :label="buyerEmploymentSchema?.[getFieldKey('employerName')]?.label"
          :data-testid="`sca-${getFieldKey('employerName')}`"
          :error-messages="errorMessages('employerName', 'Enter a name.')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6" v-if="showEmployerPhone" class="py-0">
        <v-text-field
          v-model="formattedEmployerPhone"
          :label="buyerEmploymentSchema?.[getFieldKey('employerPhone')]?.label"
          :maxlength="12"
          type="tel"
          :data-testid="`sca-${getFieldKey('employerPhone')}`"
          :error-messages="errorMessages('employerPhone', 'Enter a valid 10-digit phone number.')"
          @keydown="onlyAllowNumbers"
          @paste.prevent="handlePaste"
        />
      </v-col>
      <v-col cols="12" lg="6" v-if="showEmploymentTitle && !showEmployerName" class="py-0">
        <v-text-field
          v-model="localEmploymentDetails[getFieldKey('employmentTitle')]"
          :label="buyerEmploymentSchema?.[getFieldKey('employmentTitle')]?.label"
          :data-testid="`sca-${getFieldKey('employmentTitle')}`"
          :error-messages="errorMessages('employmentTitle', 'Enter a title.')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" v-if="showEmploymentTitle && showEmployerName" class="py-0">
        <!-- this field is in twice (see above), for formatting- todo: possibly find a way to remove second field-->
        <v-text-field
          v-model="localEmploymentDetails[getFieldKey('employmentTitle')]"
          :label="buyerEmploymentSchema?.[getFieldKey('employmentTitle')]?.label"
          :data-testid="`sca-${getFieldKey('employmentTitle')}`"
          :error-messages="errorMessages('employmentTitle', 'Enter a title.')"
          :maxlength="20"
        ></v-text-field>
      </v-col>
      <v-col cols="6" lg="3" v-if="employmentStatusCodeSelected && !isPrevious" class="py-0">
        <v-select
          :label="fieldsMap.get(coPrefix + 'employment.employment-start-month.Label')?.value"
          v-model="localEmploymentDetails[getFieldKey('employerStartMonth')]"
          :items="validMonthOptions"
          item-title="text"
          item-value="value"
          :data-testid="`sca-${getFieldKey('employerStartMonth')}`"
          :error-messages="errorMessages('employerStartMonth', 'Select a month.')"
        />
      </v-col>
      <v-col cols="12" lg="6" v-else-if="employmentStatusCodeSelected && isPrevious" class="py-0">
        <v-text-field
          :label="buyerEmploymentSchema?.['previousMonthsOnJob']?.label"
          v-model="localEmploymentDetails.previousMonthsOnJobString"
          :data-testid="`sca-previousMonthsOnJob`"
          @keydown="onlyAllowNumbers"
          type="tel"
          maxlength="3"
          minlength="1"
          :error-messages="
            hasError('previousMonthsOnJob')
              ? [
                  buyerEmploymentSchema?.['previousMonthsOnJob']?.validationMessage ||
                    'Previous months on job',
                ]
              : []
          "
        />
      </v-col>
      <v-col cols="6" lg="3" v-if="employmentStatusCodeSelected && !isPrevious" class="py-0">
        <v-select
          :label="fieldsMap.get(coPrefix + 'employment.employment-start-year.Label')?.value"
          v-model="localEmploymentDetails[getFieldKey('employerStartYear')]"
          :items="validYearOptions"
          variant="underlined"
          :data-testid="`sca-${getFieldKey('employerStartYear')}`"
          :error-messages="errorMessages('employerStartYear', 'Select a year.')"
        />
      </v-col>
    </v-row>
  </div>

  <div v-if="showIncome" :class="isPrevious ? 'previous-employment pay-section' : 'pay-section'">
    <div id="pay-type-toggle" v-if="showEmployerName" class="mb-8">
      <v-row>
        <v-col cols="12" class="toggle-buttons">
          <p class="logic-selector-label">
            {{ fieldsMap.get(coPrefix + 'employment.incomeIntervalCode.Label')?.value }}
          </p>
          <v-btn-toggle
            v-model="localEmploymentDetails[getFieldKey('incomeTypeCode')]"
            @update:modelValue="updateIncomeType"
            class="logic-selector mt-1"
            mandatory
          >
            <v-btn
              :value="IncomeTypes.HOURLY"
              :disabled="isSelectedIncomeType(IncomeTypes.HOURLY)"
              @click="handleToggle('incomeType', IncomeTypes.HOURLY)"
              :data-testid="getToggleTypeTestId('incomeTypeCode', IncomeTypes.HOURLY)"
            >
              {{ fieldsMap.get(coPrefix + 'employment.incomeIntervalCode.hourly.Label')?.value }}
            </v-btn>
            <v-btn
              :value="IncomeTypes.SALARY"
              :disabled="isSelectedIncomeType(IncomeTypes.SALARY)"
              @click="handleToggle('incomeType', IncomeTypes.SALARY)"
              :data-testid="getToggleTypeTestId('incomeTypeCode', IncomeTypes.SALARY)"
            >
              {{ fieldsMap.get(coPrefix + 'employment.incomeIntervalCode.salary.Label')?.value }}
            </v-btn>
          </v-btn-toggle>
          <p v-if="hasError(getFieldKey('incomeTypeCode'))" class="error-text">
            {{ errorMessages('incomeTypeCode', 'Select a pay type.')[0] }}
          </p>
        </v-col>
      </v-row>
    </div>

    <div id="hourly-section" class="hourly-section" v-if="isHourlyWithEmployer">
      <v-row>
        <v-col cols="12">
          <p class="mb-4">
            {{ fieldsMap.get(coPrefix + 'employment.hourly-pay-notes.fieldNotes')?.value }}
          </p>
        </v-col>
        <v-col cols="12" lg="6" xl="4" class="py-0">
          <v-text-field
            :label="fieldsMap.get(coPrefix + 'employment.hourly-pay-rate.Label')?.value"
            v-model="formattedHourlyPayRateAmountInput"
            @blur="handleHourlyPayRateAmount(formattedHourlyPayRateAmountInput, 'blur')"
            @focus="handleHourlyPayRateAmount(formattedHourlyPayRateAmountInput, 'focus')"
            @input="
              (event: InputEvent): void =>
                handleHourlyPayRateAmount((event.target as HTMLInputElement).value, 'input')
            "
            @keydown="onlyAllowNumbers"
            maxlength="14"
            type="tel"
            :error-messages="errorMessages('hourlyPayRate', 'Enter a pay rate.')"
            :data-testid="getDataTestId('hourlyPayRate')"
          />
        </v-col>
        <v-col cols="12" lg="6" xl="4" class="py-0">
          <v-text-field
            :label="fieldsMap.get(coPrefix + 'employment.average-weekly-hours.Label')?.value"
            v-model="localEmploymentDetails[getFieldKey('avgWeeklyHoursString')]"
            @input="preventNegativeValues"
            @keydown="onlyAllowNumbers"
            maxlength="14"
            type="tel"
            :error-messages="errorMessages('avgWeeklyHours', 'Enter a number of hours.')"
            :data-testid="getDataTestId('avgWeeklyHours')"
          />
        </v-col>
      </v-row>
    </div>

    <div id="salary-section" class="salary-section" v-if="shouldShowSalarySection">
      <v-row>
        <v-col cols="12" class="toggle-buttons">
          <p class="logic-selector-label">
            {{ fieldsMap.get(coPrefix + 'employment.income-frequency.Label')?.value }}
          </p>
          <v-btn-toggle
            v-model="localEmploymentDetails[getFieldKey('annualOrMonthly')]"
            @update:modelValue="updateIncomeInterval"
            class="logic-selector mt-1"
            mandatory
          >
            <v-btn
              :value="IncomeFrequency.ANNUAL"
              @click="handleToggle('salaryType', IncomeFrequency.ANNUAL)"
              :disabled="
                localEmploymentDetails[getFieldKey('incomeIntervalCode')] === IncomeFrequency.ANNUAL
              "
              :data-testid="`sca-${getFieldKey('incomeIntervalCode')}-${IncomeFrequency.ANNUAL.toLowerCase()}`"
            >
              {{ fieldsMap.get(coPrefix + 'employment.income-frequency.annual.Label')?.value }}
            </v-btn>
            <v-btn
              :value="IncomeFrequency.MONTHLY"
              @click="handleToggle('salaryType', IncomeFrequency.MONTHLY)"
              :disabled="
                localEmploymentDetails[getFieldKey('incomeIntervalCode')] ===
                IncomeFrequency.MONTHLY
              "
              :data-testid="`sca-${getFieldKey('incomeIntervalCode')}-${IncomeFrequency.MONTHLY.toLowerCase()}`"
            >
              {{ fieldsMap.get(coPrefix + 'employment.income-frequency.monthly.Label')?.value }}
            </v-btn>
          </v-btn-toggle>
          <p v-if="hasError(getFieldKey('annualOrMonthly'))" class="error-text">
            {{ errorMessages('annualOrMonthly', 'Select an option.')[0] }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" v-if="isAnnualIncome" class="py-0 mt-3">
          <v-text-field
            :label="fieldsMap.get(coPrefix + 'employment.annualIncomeAmount.Label')?.value"
            v-model="formattedAnnualIncomeAmountInput"
            @blur="handleAnnualIncomeAmount(formattedAnnualIncomeAmountInput, 'blur')"
            @focus="handleAnnualIncomeAmount(formattedAnnualIncomeAmountInput, 'focus')"
            @input="
              (event: InputEvent): void =>
                handleAnnualIncomeAmount((event.target as HTMLInputElement).value, 'input')
            "
            @keydown="onlyAllowNumbers"
            maxlength="14"
            type="tel"
            name="annualIncome"
            :error-messages="errorMessages('annualIncome', 'Enter annual income.')"
            :data-testid="`sca-${getFieldKey('annualIncome')}`"
          />
        </v-col>
        <v-col cols="12" lg="6" v-if="isMonthlyIncome" class="py-0 mt-3">
          <v-text-field
            :label="fieldsMap.get(coPrefix + 'employment.monthlyIncomeAmount.Label')?.value"
            v-model="formattedMonthlyIncomeAmountInput"
            @blur="handleMonthlyIncomeAmount(formattedMonthlyIncomeAmountInput, 'blur')"
            @focus="handleMonthlyIncomeAmount(formattedMonthlyIncomeAmountInput, 'focus')"
            @input="
              (event: InputEvent): void =>
                handleMonthlyIncomeAmount((event.target as HTMLInputElement).value, 'input')
            "
            @keydown="onlyAllowNumbers"
            maxlength="14"
            type="tel"
            name="monthlyIncome"
            :error-messages="errorMessages('monthlyIncome', 'Enter monthly amount.')"
            :data-testid="`sca-${getFieldKey('monthlyIncome')}`"
          />
        </v-col>
      </v-row>
    </div>

    <div id="bonus-pay" v-if="shouldShowBonusPay">
      <p v-if="isHourlyWithEmployer" class="my-4">
        {{ fieldsMap.get(coPrefix + 'employment.hourly-bonus-tips-commission.Label')?.value }}
      </p>
      <p v-else-if="isAnnualPay" class="my-4">
        {{ fieldsMap.get(coPrefix + 'employment.annual-bonus-tips-commission.Label')?.value }}
      </p>
      <p v-else-if="isMonthlyPay" class="my-4">
        {{ fieldsMap.get(coPrefix + 'employment.monthly-bonus-tips-commission.Label')?.value }}
      </p>

      <v-radio-group
        v-model="localEmploymentDetails[getFieldKey('hasBonusPay')]"
        @update:modelValue="(selectedValue) => handleToggle('bonusPay', selectedValue)"
        hide-details="auto"
      >
        <v-radio
          :value="YesNoOptions.NO"
          :label="
            fieldsMap.get(coPrefix + 'employment.annual-bonus-tips-commission.no.Label')?.value
          "
          prepend-inner-icon="mdi-lock"
          :data-testid="`sca-${getFieldKey('hasBonusPay')}-no`"
        />
        <v-radio
          :value="YesNoOptions.YES"
          :label="
            fieldsMap.get(coPrefix + 'employment.annual-bonus-tips-commission.yes.Label')?.value
          "
          prepend-inner-icon="mdi-lock"
          :data-testid="`sca-${getFieldKey('hasBonusPay')}-yes`"
        />
      </v-radio-group>

      <v-row v-if="localEmploymentDetails[getFieldKey('hasBonusPay')] === YesNoOptions.YES">
        <v-col cols="12" lg="6" class="pb-0 pt-6">
          <v-text-field
            :label="fieldsMap.get(coPrefix + 'employment.additional-pay-amount.Label')?.value"
            v-model="formattedMonthlyBonusPayAmountInput"
            @blur="handleMonthlyBonusPayAmount(formattedMonthlyBonusPayAmountInput, 'blur')"
            @focus="handleMonthlyBonusPayAmount(formattedMonthlyBonusPayAmountInput, 'focus')"
            @input="
              (event: InputEvent): void =>
                handleMonthlyBonusPayAmount((event.target as HTMLInputElement).value, 'input')
            "
            @keydown="onlyAllowNumbers"
            maxlength="14"
            type="tel"
            :error-messages="errorMessages('monthlyBonusPay', 'Enter a monthly amount.')"
            :data-testid="`sca-${getFieldKey('monthlyBonusPay')}`"
          />
        </v-col>
      </v-row>
    </div>

    <div id="total-income" class="total-income" v-if="totalIncome && showTotalIncome">
      <!-- TODO content manage this -->
      Total income (pre-tax) = <strong>{{ totalIncome }}</strong>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions } from 'pinia';
import { numberWithCommas } from '@util/formatter';
import {
  onlyAllowNumbers,
  handlePaste,
  preventNegativeValues,
  formatPhoneNumber,
  cleanPhoneNumber,
  formatCurrencyBlur,
  formatCurrencyFocus,
  formatCurrencyInput,
  cleanCurrencyInput,
} from '@util/commonUtils';
import {
  monthOptions,
  EmploymentConfig,
  employmentConfig,
  EmploymentStatuses,
  IncomeTypes,
  IncomeFrequency,
  IncomeIntervalCodes,
  YesNoOptions,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import FormHeader from '../Includes/FormHeader.vue';
import { FieldsPropType } from '@/lib/FieldTypes';
import { Image as ScImage } from '@sitecore-jss/sitecore-jss-vue';
import ScRichText from '@components-vue/Foundation/Common/RichText';

export default defineComponent({
  name: 'EmploymentDetailsForm',
  emits: ['update:employmentDetails', 'update:employmentType', 'clear-errors'],
  data() {
    return {
      yearOptions: Array.from({ length: 51 }, (_, i) => new Date().getFullYear() - i),
      showTotalIncome: false,
      IncomeFrequency,
      IncomeTypes,
      YesNoOptions,
      previousEmploymentTooltip: false,
    };
  },
  components: {
    ScImage,
    ScRichText,
    FormHeader,
  },
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    fieldsMap: {
      type: Map as PropType<Map<String, any>>,
      default: () => ({}),
    },
    isCobuyer: {
      type: Boolean,
      default: false,
    },
    employmentDetails: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    isPrevious: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Function as PropType<(key: string) => boolean>,
      required: true,
    },
    buyerEmploymentSchema: {
      type: Object as PropType<Record<string, any> | undefined>,
      required: true,
    },
  },
  computed: {
    shouldShowSalarySection() {
      return this.paymentType === IncomeTypes.SALARY || this.isMilitary || this.isRetired;
    },
    shouldShowBonusPay() {
      return this.isHourlyWithEmployer || this.isAnnualOrMonthly;
    },
    employmentCode() {
      return this.employmentStatusCode;
    },
    isMilitary() {
      return (
        this.employmentCode === (this.isPrevious ? this.previousMilitaryLabel : this.militaryLabel)
      );
    },
    isRetired() {
      return (
        this.employmentCode === (this.isPrevious ? this.previousRetiredLabel : this.retiredLabel)
      );
    },
    isHourlyWithEmployer() {
      return this.paymentType === IncomeTypes.HOURLY && this.showEmployerName;
    },
    isAnnualOrMonthly() {
      const value = this.localEmploymentDetails[this.getFieldKey('annualOrMonthly')];
      return value !== null && value !== '' && value !== undefined;
    },
    isAnnualPay() {
      return (
        this.localEmploymentDetails[this.getFieldKey('annualOrMonthly')] === IncomeFrequency.ANNUAL
      );
    },
    isMonthlyPay() {
      return (
        this.localEmploymentDetails[this.getFieldKey('annualOrMonthly')] === IncomeFrequency.MONTHLY
      );
    },
    selectedIncomeTypeCode: {
      get() {
        return this.localEmploymentDetails[this.getFieldKey('incomeTypeCode')];
      },
      set(value) {
        this.localEmploymentDetails[this.getFieldKey('incomeTypeCode')] = value;
      },
    },
    isAnnualIncome() {
      return this.annualOrMonthly === IncomeFrequency.ANNUAL;
    },
    isMonthlyIncome() {
      return this.annualOrMonthly === IncomeFrequency.MONTHLY;
    },
    employmentStatusCode() {
      return this.getFieldValue('employmentStatusCode');
    },
    startMonth() {
      return this.getFieldValue('employerStartMonth');
    },
    paymentType() {
      return this.getFieldValue('incomeTypeCode');
    },
    hourlyPayRate() {
      return this.getFieldValue('hourlyPayRateString');
    },
    averageWeeklyHours() {
      return this.getFieldValue('avgWeeklyHoursString');
    },
    showEmployerName() {
      return this.employmentConfigSelected.showEmployerName;
    },
    showEmployerPhone() {
      return this.employmentConfigSelected.showEmployerPhone;
    },
    showEmploymentTitle() {
      return this.employmentConfigSelected.showEmploymentTitle;
    },
    showIncome() {
      return this.employmentConfigSelected.showIncome;
    },
    annualIncome() {
      return this.getFieldValue('annualIncomeString');
    },
    monthlyIncome() {
      return this.getFieldValue('monthlyIncomeString');
    },
    annualOrMonthly() {
      return this.getFieldValue('annualOrMonthly');
    },
    bonusPay() {
      return this.getFieldValue('monthlyBonusPayString');
    },
    totalIncome(): string | undefined {
      if (!this.showIncome) return undefined;

      const hourlyRate = parseFloat(cleanCurrencyInput(this.hourlyPayRate)) || 0;
      const weeklyHours = parseFloat(cleanCurrencyInput(this.averageWeeklyHours)) || 0;
      const annualSalary = parseFloat(cleanCurrencyInput(this.annualIncome)) || 0;
      const monthlySalary = parseFloat(cleanCurrencyInput(this.monthlyIncome)) || 0;
      const bonus = parseFloat(cleanCurrencyInput(this.bonusPay)) || 0;

      return this.calculateIncome(hourlyRate, weeklyHours, annualSalary, monthlySalary, bonus);
    },
    monthOptions() {
      return monthOptions;
    },
    validMonthOptions() {
      const startYear = this.localEmploymentDetails[this.getFieldKey('employerStartYear')];

      if (startYear === new Date().getFullYear()) {
        return this.monthOptions.slice(0, new Date().getMonth() + 1);
      }
      return this.monthOptions;
    },
    validYearOptions() {
      const startMonth = this.localEmploymentDetails[this.getFieldKey('employerStartMonth')];

      if (startMonth > new Date().getMonth() + 1) {
        return this.yearOptions.slice(1);
      }
      return this.yearOptions;
    },
    employmentConfigSelected(): EmploymentConfig {
      return (
        employmentConfig?.[this.employmentStatusCode] ?? {
          showEmployerName: false,
          showEmployerPhone: false,
          showEmploymentTitle: false,
          showIncome: false,
        }
      );
    },
    employmentStatusCodeSelected() {
      return !!this.employmentStatusCode;
    },
    employmentStatusMapper() {
      return {
        [this.employedLabel]: EmploymentStatuses.FULL_TIME,
        [this.militaryLabel]: EmploymentStatuses.MILITARY,
        [this.retiredLabel]: EmploymentStatuses.RETIRED,
        [this.unemployedLabel]: EmploymentStatuses.NOT_APPLICABLE,
        [this.otherLabel]: EmploymentStatuses.SELF_EMPLOYED,
      };
    },
    employmentStatusMapperPrevious() {
      return {
        [this.previousEmployedLabel]: EmploymentStatuses.FULL_TIME,
        [this.previousMilitaryLabel]: EmploymentStatuses.MILITARY,
        [this.previousRetiredLabel]: EmploymentStatuses.RETIRED,
        [this.previousUnemployedLabel]: EmploymentStatuses.NOT_APPLICABLE,
        [this.previousOtherLabel]: EmploymentStatuses.SELF_EMPLOYED,
      };
    },
    employedLabel() {
      return (
        this.fieldsMap.get(
          `${this.coPrefix}employment.primary-job-income.primary-job-income-employed.Label`
        )?.value || ''
      );
    },
    previousEmployedLabel() {
      return (
        this.fieldsMap.get(`${this.coPrefix}employment.primary-job-income.Employed.Label`)?.value ||
        ''
      );
    },
    militaryLabel() {
      return (
        this.fieldsMap.get(
          `${this.coPrefix}employment.primary-job-income.primary-job-income-military.Label`
        )?.value || ''
      );
    },
    previousMilitaryLabel() {
      return (
        this.fieldsMap.get(`${this.coPrefix}employment.primary-job-income.Military.Label`)?.value ||
        ''
      );
    },
    retiredLabel() {
      return (
        this.fieldsMap.get(
          `${this.coPrefix}employment.primary-job-income.primary-job-income-retired.Label`
        )?.value || ''
      );
    },
    previousRetiredLabel() {
      return (
        this.fieldsMap.get(`${this.coPrefix}employment.primary-job-income.Retired.Label`)?.value ||
        ''
      );
    },
    unemployedLabel() {
      return (
        this.fieldsMap.get(
          `${this.coPrefix}employment.primary-job-income.primary-job-income-unemployed.Label`
        )?.value || ''
      );
    },
    previousUnemployedLabel() {
      return (
        this.fieldsMap.get(`${this.coPrefix}employment.primary-job-income.Unemployed.Label`)
          ?.value || ''
      );
    },
    otherLabel() {
      return (
        this.fieldsMap.get(
          `${this.coPrefix}employment.primary-job-income.primary-job-income-other.Label`
        )?.value || ''
      );
    },
    previousOtherLabel() {
      return (
        this.fieldsMap.get(`${this.coPrefix}employment.primary-job-income.Other.Label`)?.value || ''
      );
    },
    getEmploymentTitle() {
      return this.fieldsMap.get(this.coPrefix + 'employment.Label')?.value;
    },
    employmentStatusCodeValidationMessage() {
      return (
        this.buyerEmploymentSchema?.[
          this.isPrevious ? 'previousEmploymentStatusCode' : 'employmentStatusCode'
        ]?.validationMessage || 'Select an employment status.'
      );
    },
    localEmploymentDetails: {
      get() {
        return this.employmentDetails;
      },
      set(newValues) {
        this.$emit('update:employmentDetails', newValues);
      },
    },
    selectedEmploymentStatusCode: {
      get() {
        const code =
          this.localEmploymentDetails[
            this.isPrevious ? 'previousEmploymentStatusCode' : 'employmentStatusCode'
          ];
        return code === EmploymentStatuses.PART_TIME ? EmploymentStatuses.FULL_TIME : code;
      },
      set(value) {
        this.localEmploymentDetails[
          this.isPrevious ? 'previousEmploymentStatusCode' : 'employmentStatusCode'
        ] = value;
      },
    },
    coPrefix() {
      let prefix = '';
      if (this.isCobuyer) prefix += 'co-applicant-';
      if (this.isPrevious) prefix += 'previous-';
      return prefix;
    },
    formattedEmployerPhone: {
      get(): string {
        return this.formatPhoneNumber(
          this.localEmploymentDetails[this.getFieldKey('employerPhone')]
        );
      },
      set(value: string) {
        this.localEmploymentDetails[this.getFieldKey('employerPhone')] =
          this.cleanPhoneNumber(value);
      },
    },
    formattedHourlyPayRateAmountInput: {
      get(): string {
        return this.formatCurrencyInput(
          this.localEmploymentDetails[this.getFieldKey('hourlyPayRateString')]
        );
      },
      set(value: string) {
        this.localEmploymentDetails[this.getFieldKey('hourlyPayRateString')] =
          this.formatCurrencyInput(value);
      },
    },
    formattedAnnualIncomeAmountInput: {
      get(): string {
        return this.formatCurrencyInput(
          this.localEmploymentDetails[this.getFieldKey('annualIncomeString')]
        );
      },
      set(value: string) {
        this.localEmploymentDetails[this.getFieldKey('annualIncomeString')] =
          this.formatCurrencyInput(value);
      },
    },
    formattedMonthlyIncomeAmountInput: {
      get(): string {
        return this.formatCurrencyInput(
          this.localEmploymentDetails[this.getFieldKey('monthlyIncomeString')]
        );
      },
      set(value: string) {
        this.localEmploymentDetails[this.getFieldKey('monthlyIncomeString')] =
          this.formatCurrencyInput(value);
      },
    },
    formattedMonthlyBonusPayAmountInput: {
      get(): string {
        return this.formatCurrencyInput(
          this.localEmploymentDetails[this.getFieldKey('monthlyBonusPayString')]
        );
      },
      set(value: string) {
        this.localEmploymentDetails[this.getFieldKey('monthlyBonusPayString')] =
          this.formatCurrencyInput(value);
      },
    },
  },
  methods: {
    isSelectedIncomeType(type) {
      return this.selectedIncomeTypeCode === type;
    },
    updateIncomeInterval(value) {
      this.localEmploymentDetails[this.getFieldKey('incomeIntervalCode')] =
        value === IncomeFrequency.ANNUAL
          ? IncomeIntervalCodes.YEARLY
          : value === IncomeFrequency.MONTHLY
            ? IncomeIntervalCodes.MONTHLY
            : '';
    },
    getDefaultMessage(field) {
      const messages = {
        hourlyPayRate: 'Enter a pay rate.',
        avgWeeklyHours: 'Enter a number of hours.',
      };
      return messages[field] || 'This field is required.';
    },
    updateIncomeType(value) {
      this.localEmploymentDetails[this.getFieldKey('incomeIntervalCode')] =
        value === IncomeTypes.HOURLY ? IncomeIntervalCodes.YEARLY : '';
    },
    getToggleTypeTestId(field, optionName) {
      return `sca-${this.getFieldKey(field)}-${optionName.toLowerCase()}`;
    },
    getFieldKey(field) {
      const fieldStr = String(field);
      return this.isPrevious
        ? `previous${fieldStr.charAt(0).toUpperCase()}${fieldStr.slice(1)}`
        : String(field);
    },
    errorMessages(field, defaultMessage) {
      return this.hasError(this.getFieldKey(field))
        ? [
            this.buyerEmploymentSchema?.[this.getFieldKey(field)]?.validationMessage ||
              defaultMessage,
          ]
        : [];
    },
    getDataTestId(field) {
      const fieldStr = String(field);
      return `sca-${
        this.isPrevious
          ? `previous${fieldStr.charAt(0).toUpperCase()}${fieldStr.slice(1)}`
          : fieldStr
      }`;
    },
    ...mapActions(useStandaloneCreditAppStore, ['clearErrors']),
    setIncome(incomeAmount) {
      this.showTotalIncome = Boolean(incomeAmount);
      this.localEmploymentDetails[this.isPrevious ? 'previousIncomeAmount' : 'incomeAmount'] =
        incomeAmount;
    },
    handleToggle(selectionType, selectedValue) {
      this.clearErrors();
      this.$emit('clear-errors');

      const isPrevious = this.isPrevious;
      const handlers = {
        bonusPay: () => this.resetBonusPay(isPrevious, selectedValue),
        employmentType: () => this.resetEmploymentDetails(isPrevious),
        incomeType: () => this.resetIncomeDetails(isPrevious, selectedValue),
        salaryType: () => this.resetSalaryOrIncome(isPrevious, selectedValue),
      };

      if (handlers[selectionType]) {
        handlers[selectionType]();
      }
    },
    resetField(field, value) {
      this.localEmploymentDetails[field] = value;
    },
    resetBonusPay(isPrevious, selectedValue) {
      this.resetField(
        this.getFieldName('MonthlyBonusPayString', isPrevious),
        selectedValue === 'No'
          ? ''
          : this.localEmploymentDetails[this.getFieldName('MonthlyBonusPayString', isPrevious)]
      );

      this.resetField(
        this.getFieldName('MonthlyBonusPay', isPrevious),
        selectedValue === 'No'
          ? 0
          : this.localEmploymentDetails[this.getFieldName('MonthlyBonusPay', isPrevious)]
      );
    },
    resetEmploymentDetails(isPrevious) {
      const fields = [
        'EmploymentTitle',
        'EmployerName',
        'EmployerPhone',
        'EmployerStartMonth',
        'EmployerStartYear',
        'MonthsOnJob',
        'MonthsOnJobString',
      ].map((key) => this.getFieldName(key, isPrevious));

      fields.forEach((field) =>
        this.resetField(field, field.includes('Month') || field.includes('Year') ? undefined : '')
      );

      this.resetIncomeDetails(isPrevious);
      this.resetSalaryOrIncome(isPrevious);
    },
    resetSalaryOrIncome(isPrevious, selectedValue?) {
      const resetAllFields = [
        'AnnualIncomeString',
        'AnnualIncome',
        'MonthlyIncomeString',
        'MonthlyIncome',
        'AnnualOrMonthly',
      ];

      const resetFields = !selectedValue
        ? resetAllFields
        : selectedValue === IncomeFrequency.ANNUAL
          ? ['MonthlyIncomeString', 'MonthlyIncome']
          : ['AnnualIncomeString', 'AnnualIncome'];

      resetFields
        .map((key) => this.getFieldName(key, isPrevious))
        .forEach((field, index) =>
          this.resetField(
            field,
            resetFields[index] === 'AnnualOrMonthly' || field.includes('String') ? '' : 0
          )
        );
    },
    resetIncomeDetails(isPrevious, selectedValue?) {
      const resetAllFields = [
        'AnnualIncomeString',
        'AnnualIncome',
        'MonthlyIncomeString',
        'MonthlyIncome',
        'HourlyPayRateString',
        'HourlyPayRate',
        'AvgWeeklyHoursString',
        'AvgWeeklyHours',
        'IncomeTypeCode',
      ];

      const isHourly = selectedValue === IncomeTypes.HOURLY;

      const resetFields = !selectedValue
        ? resetAllFields
        : isHourly
          ? ['AnnualIncomeString', 'AnnualIncome', 'MonthlyIncomeString', 'MonthlyIncome']
          : ['HourlyPayRateString', 'HourlyPayRate', 'AvgWeeklyHoursString', 'AvgWeeklyHours'];

      resetFields
        .map((key) => this.getFieldName(key, isPrevious))
        .forEach((field, index) =>
          this.resetField(
            field,
            resetFields[index] === 'IncomeTypeCode' || field.includes('String') ? '' : 0
          )
        );
    },
    getFieldName(baseName: string, isPrevious: boolean) {
      return isPrevious
        ? `previous${baseName}`
        : baseName.charAt(0).toLowerCase() + baseName.slice(1);
    },
    getFieldValue(field) {
      return this.localEmploymentDetails[
        this.isPrevious ? `previous${field.charAt(0).toUpperCase()}${field.slice(1)}` : field
      ];
    },
    calculateIncome(hourlyRate, weeklyHours, annualSalary, monthlySalary, bonus) {
      if (this.paymentType === IncomeTypes.HOURLY) {
        const yearlyPay = Math.round(hourlyRate * weeklyHours * 52 + bonus * 12);
        this.setIncome(yearlyPay);
        return `${this.formatPrice(yearlyPay)}/yr or ${this.formatPrice(Math.round(yearlyPay / 12))}/mo`;
      }

      if (this.annualOrMonthly === IncomeFrequency.ANNUAL) {
        const monthlyPay = Math.round(annualSalary / 12) + bonus;
        const yearlyPay = Math.round(annualSalary) + bonus * 12;
        this.setIncome(yearlyPay);
        return `${this.formatPrice(monthlyPay)}/mo`;
      }

      if (this.annualOrMonthly === 'Monthly') {
        const yearlyPay = Math.round(monthlySalary * 12) + bonus * 12;
        const monthlyPay = Math.round(monthlySalary) + bonus;
        this.setIncome(monthlyPay);
        return `${this.formatPrice(yearlyPay)}/yr`;
      }

      return undefined;
    },
    onlyAllowNumbers,
    handlePaste,
    preventNegativeValues,
    formatPhoneNumber,
    cleanPhoneNumber,
    handleEmployerPhoneInput(event: Event) {
      const target = event.target as HTMLInputElement;
      const cleaned = this.cleanPhoneNumber(target.value);
      this.localEmploymentDetails[this.getFieldKey('employerPhone')] = cleaned;
    },
    formatCurrencyBlur,
    formatCurrencyFocus,
    formatCurrencyInput,
    formatPrice(amount: number) {
      return `$${numberWithCommas(amount)}`;
    },
    handleHourlyPayRateAmount(value: string, eventType: 'blur' | 'focus' | 'input' = 'input') {
      this.localEmploymentDetails[this.getFieldKey('hourlyPayRateString')] =
        eventType === 'blur'
          ? this.formatCurrencyBlur(value)
          : eventType === 'focus'
            ? this.formatCurrencyFocus(value)
            : this.formatCurrencyInput(value);
    },
    handleAnnualIncomeAmount(value: string, eventType: 'blur' | 'focus' | 'input' = 'input') {
      this.localEmploymentDetails[this.getFieldKey('annualIncomeString')] =
        eventType === 'blur'
          ? this.formatCurrencyBlur(value)
          : eventType === 'focus'
            ? this.formatCurrencyFocus(value)
            : this.formatCurrencyInput(value);
    },
    handleMonthlyIncomeAmount(value: string, eventType: 'blur' | 'focus' | 'input' = 'input') {
      this.localEmploymentDetails[this.getFieldKey('monthlyIncomeString')] =
        eventType === 'blur'
          ? this.formatCurrencyBlur(value)
          : eventType === 'focus'
            ? this.formatCurrencyFocus(value)
            : this.formatCurrencyInput(value);
    },
    handleMonthlyBonusPayAmount(value: string, eventType: 'blur' | 'focus' | 'input' = 'input') {
      this.localEmploymentDetails[this.getFieldKey('monthlyBonusPayString')] =
        eventType === 'blur'
          ? this.formatCurrencyBlur(value)
          : eventType === 'focus'
            ? this.formatCurrencyFocus(value)
            : this.formatCurrencyInput(value);
    },
  },
});
</script>
<style lang="scss">
@use '@/assets/styles/variables' as *;

.pay-section {
  .total-income {
    color: $park-gray-20;
    background: $park-gray-100;
    border: 1px solid $park-gray-90;
    border-radius: $border-radius-md;
    padding: $spacing_squish-nano;
    font-size: $font-size-xxxxs;
    line-height: $line-height-lg;
    width: 100%;
    margin-top: $spacing_stack-xxs;
    margin-bottom: $spacing_stack-md;

    @include media-breakpoint-up(lg) {
      width: fit-content;
    }
  }
}
.error-text {
  color: $supplementary-red-50;
  font-size: 12px;
  margin-top: $spacing_stack-nano;
}
.previous-employment {
  &.employment-status-section {
    margin-top: $spacing_stack-sm;
  }
}
</style>
