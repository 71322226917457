import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tooltip-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormHeader = _resolveComponent("FormHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_BuyerAddressDetails = _resolveComponent("BuyerAddressDetails")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: "pb-6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormHeader, {
              title: _ctx.fieldsMap.get(_ctx.coPrefix + 'current-address.Label')?.value,
              note: _ctx.fieldsMap.get(_ctx.coPrefix + 'current-address.Description')
            }, null, 8, ["title", "note"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.isCobuyer)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "same-address-checkbox"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_checkbox_btn, {
                  modelValue: _ctx.currentAddress.coApplicantSameAddress,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentAddress.coApplicantSameAddress) = $event)),
                  label: 
          _ctx.fieldsMap.get('co-applicant-current-address.same-address-as-applicant.Label')?.value
        ,
                  class: "mt-4",
                  "data-testid": "cobuyer-same-address"
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_BuyerAddressDetails, {
      fields: _ctx.fields,
      fieldsMap: _ctx.fieldsMap,
      isCobuyer: _ctx.isCobuyer,
      formValues: _ctx.currentAddress,
      "onUpdate:formValues": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentAddress) = $event)),
      buyerAddressSchema: _ctx.buyerAddressSchema,
      stateOptions: _ctx.stateOptions,
      residenceTypeOptions: _ctx.residenceTypeOptions,
      hasError: _ctx.hasError,
      dataTestIdSuffixes: _ctx.dataTestIdSuffixes
    }, null, 8, ["fields", "fieldsMap", "isCobuyer", "formValues", "buyerAddressSchema", "stateOptions", "residenceTypeOptions", "hasError", "dataTestIdSuffixes"]),
    (_ctx.includePreviousAddresss)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "pb-6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormHeader, {
                  title: _ctx.fieldsMap.get(_ctx.coPrefix + 'previous-address.Label')?.value
                }, {
                  tooltip: _withCtx(() => [
                    _createVNode(_component_sc_rich_text, {
                      field: 
              _ctx.fieldsMap.get(_ctx.coPrefix + 'previous-address.previous-address-icon.helperText')?.value
            ,
                      "data-testid": "previous-address-icon--helperText"
                    }, null, 8, ["field"]),
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_v_tooltip, {
                        modelValue: _ctx.previousAddressTooltip,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.previousAddressTooltip) = $event)),
                        location: "top",
                        "open-on-hover": false,
                        text: 
                _ctx.fieldsMap.get(_ctx.coPrefix + 'previous-address.previous-address-icon.helperText')?.value
              ,
                        class: "top",
                        width: "250"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_sc_image, _mergeProps({
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.previousAddressTooltip = !_ctx.previousAddressTooltip)),
                            onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.previousAddressTooltip = !_ctx.previousAddressTooltip), ["enter"])),
                            class: "tooltip-trigger",
                            media: 
                    _ctx.fieldsMap.get(_ctx.coPrefix + 'previous-address.previous-address-icon.pageImage')
                  ,
                            tabindex: "0"
                          }, props, {
                            width: "20",
                            height: "20"
                          }), null, 16, ["media"])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "text"])
                    ])
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.includePreviousAddresss)
      ? (_openBlock(), _createBlock(_component_BuyerAddressDetails, {
          key: 2,
          fields: _ctx.fields,
          fieldsMap: _ctx.fieldsMap,
          isCobuyer: _ctx.isCobuyer,
          formValues: _ctx.previousAddress,
          "onUpdate:formValues": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.previousAddress) = $event)),
          buyerAddressSchema: _ctx.buyerAddressSchema,
          stateOptions: _ctx.stateOptions,
          residenceTypeOptions: _ctx.residenceTypeOptionsPrevious,
          hasError: _ctx.hasError,
          isPrevious: true
        }, null, 8, ["fields", "fieldsMap", "isCobuyer", "formValues", "buyerAddressSchema", "stateOptions", "residenceTypeOptions", "hasError"]))
      : _createCommentVNode("", true)
  ], 64))
}