import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "logic-selector-label" }
const _hoisted_3 = {
  key: 0,
  class: "error-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.localFormValues.coApplicantSameAddress)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "6",
              class: "same-address-details"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('current-address-label')), 1),
                _createElementVNode("p", {
                  class: "small",
                  innerHTML: _ctx.formattedAddress(false, false)
                }, null, 8, _hoisted_1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "6",
              class: "py-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.localFormValues[_ctx.getFieldKey('addressLine1')],
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormValues[_ctx.getFieldKey('addressLine1')]) = $event)),
                  label: _ctx.buyerAddressSchema?.[_ctx.getFieldKey('addressLine1')]?.label,
                  "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.addressLine1}`,
                  "error-messages": 
          _ctx.hasError(_ctx.getFieldKey('addressLine1'))
            ? [
                _ctx.buyerAddressSchema?.[_ctx.getFieldKey('addressLine1')]?.validationMessage ||
                  'Enter a valid street',
              ]
            : []
        
                }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "6",
              class: "py-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.localFormValues[_ctx.getFieldKey('addressLine2')],
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFormValues[_ctx.getFieldKey('addressLine2')]) = $event)),
                  label: _ctx.buyerAddressSchema?.[_ctx.getFieldKey('addressLine2')]?.label,
                  "data-testid": `sca-${_ctx.getFieldKey('addressLine2')}`,
                  "error-messages": 
          _ctx.hasError(_ctx.getFieldKey('addressLine2'))
            ? [
                _ctx.buyerAddressSchema?.[_ctx.getFieldKey('addressLine2')]?.validationMessage ||
                  'Enter a valid street',
              ]
            : []
        
                }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })),
    (!_ctx.formValues.coApplicantSameAddress)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "8",
              lg: "6",
              class: "py-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.localFormValues[_ctx.getFieldKey('city')],
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localFormValues[_ctx.getFieldKey('city')]) = $event)),
                  label: _ctx.buyerAddressSchema?.[_ctx.getFieldKey('city')]?.label,
                  "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.city}`,
                  "error-messages": 
          _ctx.hasError(_ctx.getFieldKey('city'))
            ? [_ctx.buyerAddressSchema?.[_ctx.getFieldKey('city')]?.validationMessage || 'Enter a valid city']
            : []
        
                }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "6",
              md: "4",
              lg: "3",
              xl: "2",
              class: "py-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.localFormValues[_ctx.getFieldKey('state')],
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localFormValues[_ctx.getFieldKey('state')]) = $event)),
                  label: _ctx.buyerAddressSchema?.[_ctx.getFieldKey('state')]?.label,
                  "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.state}`,
                  items: _ctx.stateOptions,
                  variant: "underlined",
                  "error-messages": 
          _ctx.hasError(_ctx.getFieldKey('state'))
            ? [
                _ctx.buyerAddressSchema?.[_ctx.getFieldKey('state')]?.validationMessage ||
                  'Enter a valid state',
              ]
            : []
        
                }, null, 8, ["modelValue", "label", "data-testid", "items", "error-messages"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "6",
              md: "4",
              lg: "3",
              xl: "2",
              class: "py-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.localFormValues[_ctx.getFieldKey('zipCode')],
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localFormValues[_ctx.getFieldKey('zipCode')]) = $event)),
                  label: _ctx.buyerAddressSchema?.[_ctx.getFieldKey('zipCode')]?.label,
                  "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.zipCode}`,
                  onKeydown: _ctx.onlyAllowNumbers,
                  onPaste: _withModifiers(_ctx.handlePaste, ["prevent"]),
                  "error-messages": 
          _ctx.hasError(_ctx.getFieldKey('zipCode'))
            ? [
                _ctx.buyerAddressSchema?.[_ctx.getFieldKey('zipCode')]?.validationMessage ||
                  'Enter a valid zipcode',
              ]
            : []
        ,
                  minlength: "5",
                  maxlength: "5",
                  type: "tel"
                }, null, 8, ["modelValue", "label", "data-testid", "onKeydown", "onPaste", "error-messages"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: "sca-residence-label"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.fieldsMap.get(
            _ctx.isPrevious
              ? _ctx.coPrefix + 'previous-address.residenceTypeCode.Label'
              : _ctx.coPrefix + 'current-address.residenceTypeCode.Label'
          )?.value), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          class: "toggle-buttons mb-4 py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn_toggle, {
              modelValue: _ctx.localFormValues[_ctx.getFieldKey('residenceTypeCode')],
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localFormValues[_ctx.getFieldKey('residenceTypeCode')]) = $event)),
              "data-testid": `sca-${_ctx.getFieldKey('residenceTypeCode')}`,
              class: "logic-selector",
              mandatory: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.residenceTypeOptions, (option, index) => {
                  return (_openBlock(), _createBlock(_component_v_btn, {
                    key: index,
                    value: option.value,
                    class: "residence-type-option",
                    "data-testid": `sca-${_ctx.getFieldKey('residenceTypeCode')}-${option.value}`,
                    "error-messages": 
            _ctx.hasError(_ctx.getFieldKey('residenceTypeCode'))
              ? [
                  _ctx.buyerAddressSchema?.[_ctx.getFieldKey('residenceTypeCode')]?.validationMessage ||
                    'Select a residence',
                ]
              : []
          
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["value", "data-testid", "error-messages"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "data-testid"]),
            (_ctx.hasError(_ctx.getFieldKey('residenceTypeCode')))
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.buyerAddressSchema?.[_ctx.getFieldKey('residenceTypeCode')]?.validationMessage ||
          'Select a residence type.'), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        (_ctx.residenceTypeCodeSelected)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 0,
              cols: "6",
              lg: "4",
              class: "move-in-month"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.localFormValues[_ctx.getFieldKey('moveInMonth')],
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localFormValues[_ctx.getFieldKey('moveInMonth')]) = $event)),
                  label: _ctx.fieldsMap.get(_ctx.coPrefix + 'current-address.moveInMonth.Label')?.value,
                  "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.moveInMonth}`,
                  items: _ctx.validMonthOptions,
                  "item-title": "text",
                  "item-value": "value",
                  "error-messages": 
          _ctx.hasError(_ctx.getFieldKey('moveInMonth'))
            ? [
                _ctx.buyerAddressSchema?.[_ctx.getFieldKey('moveInMonth')]?.validationMessage ||
                  'Enter a valid move in month.',
              ]
            : []
        
                }, null, 8, ["modelValue", "label", "data-testid", "items", "error-messages"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.residenceTypeCodeSelected)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 1,
              cols: "6",
              lg: "4",
              class: "move-in-year"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.localFormValues[_ctx.getFieldKey('moveInYear')],
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localFormValues[_ctx.getFieldKey('moveInYear')]) = $event)),
                  "data-testid": `sca-${_ctx.isCobuyer ? 'cobuyer' : 'buyer'}-${_ctx.dataTestIdSuffixes.moveInYear}`,
                  label: _ctx.fieldsMap.get(_ctx.coPrefix + 'current-address.moveInYear.Label')?.value,
                  items: _ctx.validYearOptions,
                  "error-messages": 
          _ctx.hasError(_ctx.getFieldKey('moveInYear'))
            ? [
                _ctx.buyerAddressSchema?.[_ctx.getFieldKey('moveInYear')]?.validationMessage ||
                  'Enter a valid move in year.',
              ]
            : []
        
                }, null, 8, ["modelValue", "data-testid", "label", "items", "error-messages"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.residenceTypeOptions, (option) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: option.value
          }, [
            (_ctx.formValues[_ctx.getFieldKey('residenceTypeCode')] === option.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (option.showPayment)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "12",
                        lg: "4",
                        class: "py-0 pt-lg-3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.formattedRentMortgagePaymentAmountInput,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formattedRentMortgagePaymentAmountInput) = $event)),
                            label: _ctx.buyerAddressSchema?.[_ctx.getFieldKey('rentMortgagePaymentAmount')]?.label,
                            onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleRentMortgagePaymentAmount(_ctx.formattedRentMortgagePaymentAmountInput, 'blur'))),
                            onFocus: _cache[10] || (_cache[10] = ($event: any) => (
              _ctx.handleRentMortgagePaymentAmount(_ctx.formattedRentMortgagePaymentAmountInput, 'focus')
            )),
                            onInput: _cache[11] || (_cache[11] = 
              (event): void =>
                _ctx.handleRentMortgagePaymentAmount((event.target as HTMLInputElement).value, 'input')
            ),
                            onKeydown: _ctx.onlyAllowNumbers,
                            maxlength: "14",
                            type: "tel",
                            "error-messages": 
              _ctx.hasError(_ctx.getFieldKey('rentMortgagePaymentAmount'))
                ? [
                    _ctx.buyerAddressSchema?.[_ctx.getFieldKey('rentMortgagePaymentAmount')]
                      ?.validationMessage || 'Enter a valid rent or mortgage payment amount',
                  ]
                : []
            ,
                            "data-testid": `sca-${_ctx.getFieldKey('rentMortgagePaymentAmount')}`
                          }, null, 8, ["modelValue", "label", "onKeydown", "error-messages", "data-testid"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (option.showOther)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 1,
                        cols: "12",
                        lg: "4",
                        class: _normalizeClass(["py-0", option.showPayment ? '' : 'pt-lg-3'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.localFormValues[_ctx.getFieldKey('otherDetails')],
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localFormValues[_ctx.getFieldKey('otherDetails')]) = $event)),
                            "data-testid": `sca-${_ctx.getFieldKey('otherDetails')}`,
                            label: "Other Details"
                          }, null, 8, ["modelValue", "data-testid"])
                        ]),
                        _: 2
                      }, 1032, ["class"]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}