import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sca-form-header" }
const _hoisted_2 = { class: "sca-form-title" }
const _hoisted_3 = {
  key: 0,
  class: "sca-form-title--tooltip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { "no-gutters": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createElementVNode("h6", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
              (_ctx.tooltip || _ctx.$slots.tooltip)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "tooltip", {}, () => [
                      _createVNode(_component_sc_rich_text, { field: _ctx.tooltip }, null, 8, ["field"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    }),
    (_ctx.note || _ctx.$slots.note)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 0,
          "no-gutters": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "note", {}, () => [
                  _createVNode(_component_sc_rich_text, {
                    field: _ctx.note,
                    class: "sca-header-note"
                  }, null, 8, ["field"])
                ])
              ]),
              _: 3
            })
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ]))
}