import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "trade-note" }
const _hoisted_2 = { class: "tooltip-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormHeader = _resolveComponent("FormHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_SocialSecurity = _resolveComponent("SocialSecurity")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "sca-additional-info pa-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, { ref: "form" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "pb-6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormHeader, {
                    title: _ctx.fieldsMap.get('down-payment.Label')?.value,
                    note: _ctx.fieldsMap.get('down-payment.Description')
                  }, null, 8, ["title", "note"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                lg: "6",
                class: "py-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.formattedDownPaymentInput,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formattedDownPaymentInput) = $event)),
                    label: _ctx.buyerFinanceSchema?.cashDownPayment?.label,
                    "data-testid": "sca-finance-down-payment",
                    type: "tel",
                    "error-messages": 
              _ctx.hasError('cashDownPayment')
                ? [
                    _ctx.buyerFinanceSchema?.cashDownPayment?.validationMessage ||
                      'Enter a valid down payment',
                  ]
                : []
            ,
                    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleDownPaymentInput(_ctx.formattedDownPaymentInput, 'blur'))),
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleDownPaymentInput(_ctx.formattedDownPaymentInput, 'focus'))),
                    onInput: _cache[3] || (_cache[3] = 
              (event): void =>
                _ctx.handleDownPaymentInput((event.target as HTMLInputElement).value, 'input')
            ),
                    onKeydown: _ctx.onlyAllowNumbers
                  }, null, 8, ["modelValue", "label", "error-messages", "onKeydown"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "pb-6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormHeader, {
                    title: _ctx.fieldsMap.get('trade.Label')?.value,
                    class: "trade-header"
                  }, {
                    note: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_sc_rich_text, {
                          field: _ctx.fieldsMap.get('trade.Description'),
                          "data-testid": "sca-trade-description",
                          class: "sca-trade-description"
                        }, null, 8, ["field"]),
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_v_tooltip, {
                            text: _ctx.fieldsMap.get('trade.trade-selection.optional-text')?.value,
                            location: "top",
                            class: "top",
                            width: "170"
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_sc_image, _mergeProps({
                                media: _ctx.fieldsMap.get('trade.trade-selection.option-icon'),
                                tabindex: "0"
                              }, props, {
                                width: "20",
                                height: "20",
                                class: "ms-2 align-self-center"
                              }), null, 16, ["media"])
                            ]),
                            _: 1
                          }, 8, ["text"])
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["title"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_radio_group, {
                    modelValue: _ctx.trade,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.trade) = $event)),
                    "data-testid": "sca-trade"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tradeOptions, (option, index) => {
                        return (_openBlock(), _createBlock(_component_v_radio, {
                          "data-testid": `sca-trade-${index}`,
                          key: index,
                          value: option,
                          label: option
                        }, null, 8, ["data-testid", "value", "label"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_SocialSecurity, {
            fields: _ctx.fields,
            title: _ctx.fieldsMap.get('verify-identity.Label')?.value,
            subtitle: _ctx.fieldsMap.get('verify-identity.Description'),
            footnote: _ctx.fieldsMap.get('verify-identity.ssn.notes'),
            hasError: _ctx.hasError,
            personalDetailsValues: _ctx.personalDetailsValues,
            buyerPersonalDetailsSchema: _ctx.buyerPersonalDetailsSchema
          }, null, 8, ["fields", "title", "subtitle", "footnote", "hasError", "personalDetailsValues", "buyerPersonalDetailsSchema"])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}