<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <FormHeader :title="title" :note="subtitle"></FormHeader>
    </v-col>
  </v-row>
  <v-row class="sca-ss-input">
    <v-col cols="12" lg="6">
      <v-text-field
        prepend-inner-icon="mdi-lock"
        type="tel"
        v-model="formattedSSNInput"
        data-testid="sca-personal-details-ssn"
        :label="buyerPersonalDetailsSchema?.ssn?.label"
        :error-messages="
          hasError('ssn')
            ? [buyerPersonalDetailsSchema?.ssn?.validationMessage || 'Enter a valid ssn']
            : []
        "
        maxlength="9"
        @keydown="onlyAllowNumbers"
        hide-details="auto"
        name="ssn"
        autocomplete="off"
      >
        <input v-model="maskedSSN" class="masked-ssn" />
      </v-text-field>
      <sc-text :field="footnote" class="ss-privacy" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import FormHeader from '../Includes/FormHeader.vue';
import { Text, Field } from '@sitecore-jss/sitecore-jss-vue';
import { onlyAllowNumbers } from '@/util/commonUtils';

export default defineComponent({
  name: 'SocialSecurity',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: Object as PropType<Field<any>>,
      default: () => ({ value: '' }),
    },
    footnote: {
      type: Object as PropType<Field<any>>,
      default: () => ({ value: '' }),
    },
    personalDetailsValues: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    buyerPersonalDetailsSchema: {
      type: Object as PropType<Record<string, any> | undefined>,
      required: true,
    },
    hasError: {
      type: Function as PropType<(key: string) => boolean>,
      required: true,
    },
  },
  components: {
    ScText: Text,
    FormHeader,
  },
  computed: {
    localPersonalDetails: {
      get() {
        return this.personalDetailsValues;
      },
      set(newValues: string) {
        this.$emit('update:personalDetailsValues', newValues);
      },
    },
    formattedSSNInput: {
      get(): string {
        return this.localPersonalDetails.ssn;
      },
      set(value: string) {
        this.localPersonalDetails.ssn = value;
      },
    },
    maskedSSN: {
      get(): string {
        return this.maskSSN(this.localPersonalDetails.ssn);
      },
      set(value: string) {
        this.localPersonalDetails.ssn = value;
      },
    },
  },
  methods: {
    onlyAllowNumbers,
    maskSSN(value: string) {
      const length = value.length;
      const masked = `${'●'.repeat(Math.max(0, length - 1))}${value.slice(-1)}`;

      if (length === 0) return ``;
      if (length >= 5) return `${masked.slice(0, 3)}-${masked.slice(3, 5)}-${masked.slice(5, 9)}`;
      if (length >= 3) return `${masked.slice(0, 3)}-${masked.slice(3, 5)}`;
      return `${masked.slice(0, 3)}`;
    },
  },
  data() {
    return {
      showSocialSecurity: false,
    };
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-ss-input {
  color: $park-gray-20;
  font-size: $font-size-xxxxs;
  line-height: $line-height-sm; /* 133.333% */

  .v-field__input {
    position: relative;
  }
  .masked-ssn,
  [name='ssn'] {
    position: absolute;
    width: 100%;
  }
  [name='ssn'] {
    opacity: 0 !important;
    z-index: 1;
  }
  .masked-ssn {
    opacity: 1;
    z-index: 9;
    color: $base-black-100;
  }
  .ss-privacy {
    display: block;
    line-height: $line-height-sm; /* 133.333% */
    letter-spacing: 0.2px;
    margin-top: -$spacing_stack-quarck;
  }
}
</style>
