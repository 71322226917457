<template>
  <!-- There are two variations of this page, the intro or the form variation
   The intro has a single image centered at the bottom. The form variation has an image and text centered x and y -->
  <div class="sca-fullpage-illustration">
    <sc-image :media="fields.image_file" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { StartImage } from '@/lib/CommonDatasourceModels';
import { Image } from '@sitecore-jss/sitecore-jss-vue';

export default defineComponent({
  name: 'FullPageIllustration',
  props: {
    fields: {
      type: Object as PropType<StartImage>,
      default: () => ({}),
    },
  },
  components: {
    ScImage: Image,
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;
@use 'sass:math';

.sca-fullpage-illustration {
  background-color: $base-white-100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;

  img {
    width: auto;
    height: 500px;
    position: fixed;
    bottom: 0;
    left: 0;

    .page--standalonecreditapp--finish & {
      left: auto;
      right: 50%;

      @include media-breakpoint-up(lg) {
        right: math.div(100%, 12) * 7;
      }
      @include media-breakpoint-up(xl) {
        right: 800px;
      }
    }
  }
}
</style>
