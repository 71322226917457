import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel-content" }
const _hoisted_2 = { class: "large light" }
const _hoisted_3 = { class: "small" }
const _hoisted_4 = { class: "small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmploymentSection = _resolveComponent("EmploymentSection")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EmploymentSection, {
      buyer: _ctx.buyer,
      isPrevious: false,
      isCobuyer: _ctx.isCobuyer,
      formattedMonthAndYear: _ctx.formattedMonthAndYear,
      previousFormattedMonthAndYear: _ctx.previousFormattedMonthAndYear
    }, null, 8, ["buyer", "isCobuyer", "formattedMonthAndYear", "previousFormattedMonthAndYear"]),
    (_ctx.buyer?.employmentDetails?.previousEmploymentStatusCode)
      ? (_openBlock(), _createBlock(_component_EmploymentSection, {
          key: 0,
          buyer: _ctx.buyer,
          isPrevious: true,
          formattedMonthAndYear: _ctx.formattedMonthAndYear,
          previousFormattedMonthAndYear: _ctx.previousFormattedMonthAndYear
        }, null, 8, ["buyer", "formattedMonthAndYear", "previousFormattedMonthAndYear"]))
      : _createCommentVNode("", true),
    (_ctx.showExtraIncome)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "pb-0"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('extra-income-label')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('monthly-income-label')), 1),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.formatCurrency(_ctx.buyer?.employmentDetails.otherIncomeAmount)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "8"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('income-source-label')), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.buyer?.employmentDetails.otherIncomeSourceDescription), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}