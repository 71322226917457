<template>
  <div class="sca-controller" v-if="isInitialized || isStartStep">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { CurrentStepDetails } from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import { fetchCreditAppSchema } from '@services/routeOneService';
import { PipeDelimitedParser } from '@util/stringHelper';
import Cookies from 'js-cookie';
import { FieldsPropType } from '@/lib/FieldTypes';
import { isValidVIN } from '@/util/commonUtils';

export default defineComponent({
  name: 'StandaloneCreditAppController',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {},
  methods: {
    ...mapActions(useStandaloneCreditAppStore, [
      'setNavSteps',
      'setCurrentStepDetails',
      'setCurrentStepPath',
      'setApplicationSchema',
      'addError',
      'setDealJacketVehicle',
      'setVehicleOfInterest',
    ]),
    isValidVIN,
    async handleCreditAppRouteUpdate() {
      const currentComponent = this.currentFormStep?.['componentName'];

      const currentStepDetails: CurrentStepDetails = {
        componentName: currentComponent,
        formTitle: this.currentFormStep?.['fields']?.['form-title']?.value,
        nextStep: this.currentFormStep?.['fields']?.['next-step']?.value,
      };

      if (
        currentComponent === 'StandaloneCreditApp--AdditionalInfo' &&
        this.flow === 'coApplicant'
      ) {
        currentStepDetails.nextStep =
          this.currentFormStep?.['fields']?.['coapplicant-next-step']?.value;
      }

      this.setCurrentStepDetails(currentStepDetails);
      this.setCurrentStepPath(this.$route.path);
    },
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'currentStepDetails',
      'isInitialized',
      'isStartStep',
      'isDebugging',
      'flow',
    ]),
    formPlaceholders() {
      const placeholders = this.$jss.store.state.routeData.placeholders['jss-main'][0].placeholders[
        'right-content'
      ][0].placeholders['standalone-credit-app-form'] as Array<object>;

      // Enforce an expected structure for placeholders - else we can't continue forward
      if (!placeholders || placeholders.length !== 2) {
        throw new Error('Invalid placeholder structure detected. Cannot continue.');
      }

      return placeholders;
    },
    startPage() {
      const startPage = this.$jss.store.state.routeData.fields['StartPage'];
      return startPage;
    },
    schemaIndex() {
      return this.formPlaceholders.findIndex(
        (placeholder) => placeholder['componentName'] === 'StandaloneCreditAppSchemaForm'
      );
    },
    currentFormStep() {
      // Under the standalone-credit-app-form placeholder, we expect length = 2 with one
      // having componentName === StandaloneCreditAppSchemaForm and another
      // such as StandaloneCreditApp--Finish
      // We can't depend on the ordering
      return this.formPlaceholders[1 - this.schemaIndex];
    },
    schemaForm() {
      return this.formPlaceholders[this.schemaIndex];
    },
  },

  async mounted() {
    this.emitter.on('route-update', this.handleCreditAppRouteUpdate);
    this.handleCreditAppRouteUpdate();

    if (this.isStartStep) {
      // On the start page, set the nav into the store, which sets up the flow
      const nav = this.schemaForm['fields']?.navigation;
      this.setNavSteps(nav);
      const customKeys = ['VIN', 'Make', 'Model', 'Year'];
      // This is a method that returns a key-value dictionary where each key is statically assigned (e.g., VIN, Model, Make, Year).
      const parser = new PipeDelimitedParser(
        Cookies.get('ep_vehicleOfInterest') as string,
        customKeys
      );
      const vehicleOfInterestVin = parser.getKeyValuePairs()['VIN'];
      const vehicleOfInterestMake = parser.getKeyValuePairs()['Make'];
      const vehicleOfInterestModel = parser.getKeyValuePairs()['Model'];
      const yearString = parser.getKeyValuePairs()['Year'];
      const vehicleOfInterestYear = parseInt(yearString, 10);
      if (isValidVIN(vehicleOfInterestVin)) {
        this.setDealJacketVehicle(vehicleOfInterestVin); // Used for the schema submission
        this.setVehicleOfInterest(
          vehicleOfInterestVin,
          vehicleOfInterestMake,
          vehicleOfInterestModel,
          vehicleOfInterestYear
        ); // Used for analytics
      }

      try {
        // For the initial release, we have decided to fetch a static schema from routeOne
        // It was agreed that if/when the schema varies based on the dealer (unlikely for EP), we will have to make this value dynamic
        const appSchema = await fetchCreditAppSchema('YG7LD');
        this.setApplicationSchema(appSchema?.data);
      } catch (error) {
        console.error(error);
        // failed to get the schema -> show a generic error message -> cannot continue
        this.addError(this.startPage?.fields?.['submitError']?.value);
      }
    } else if (!this.isInitialized) {
      const redirectPath = this.startPage?.url ?? '/';
      this.$router.push(redirectPath);
    }
  },
  unmounted() {
    this.emitter.off('route-update', this.handleCreditAppRouteUpdate);
  },
});
</script>

<style lang="scss">
.sca-controller {
  padding: $spacing_stack-xxxs $spacing_inline-xxs $spacing_stack-xs;

  @include media-breakpoint-up(md) {
    padding-top: $spacing_stack-xxs;
  }

  @include media-breakpoint-up(lg) {
    padding: $spacing_stack-xxs $spacing_inline-sm $spacing_stack-xs;
  }
}
</style>
