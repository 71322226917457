// BuyerPersonalDetailsForm.ts

import { PersonalDetails } from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';

/**
 * Editable subset of personal details used in the form.
 */
export type BuyerPersonalDetailsForm = PersonalDetails;

/**
 * Default values for the buyer's personal details form.
 */
export const defaultPersonalDetailsValues: BuyerPersonalDetailsForm = {
  ssn: '',
  ssnDefined: false,
};
