import { Finance } from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';

/**
 * Represents the editable finance fields used in the form.
 */
export type FinanceForm = Finance;

/**
 * Default values for the finance form.
 */
export const defaultFinanceValues: FinanceForm = {
  transactionType: 'RETAIL', // default per business requirement
  vehicleCashPrice: 0, // see if needs to be undefined
  cashDownPayment: 0, // see if needs to be undefined
  cashDownPaymentString: '',
};

/**
 * Returns a fresh copy of the default finance form values.
 */
export function getDefaultFinanceValues(): FinanceForm {
  return { ...defaultFinanceValues };
}
