import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "small" }
const _hoisted_2 = { class: "small" }
const _hoisted_3 = { class: "small" }
const _hoisted_4 = { class: "small" }
const _hoisted_5 = { class: "small" }
const _hoisted_6 = { class: "small" }
const _hoisted_7 = { class: "large light" }
const _hoisted_8 = { class: "small" }
const _hoisted_9 = { class: "small" }
const _hoisted_10 = { class: "small" }
const _hoisted_11 = { class: "small" }
const _hoisted_12 = { class: "small" }
const _hoisted_13 = { class: "small" }
const _hoisted_14 = { class: "small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_ctx.isCobuyer)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('employment-status-label')), 1),
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.isPrevious
              ? _ctx.buyer?.employmentDetails.previousEmploymentStatusCode
              : _ctx.buyer?.employmentDetails.employmentStatusCode), 1)
              ]),
              _: 1
            }),
            (_ctx.employmentConfigSelected.showEmployerName)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "12",
                  lg: "4"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('employer-name-label')), 1),
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.isPrevious
              ? _ctx.buyer?.employmentDetails.previousEmployerName
              : _ctx.buyer?.employmentDetails.employerName), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.employmentConfigSelected.showEmployerPhone)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "12",
                  lg: "4"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('employer-phone-label')), 1),
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.isPrevious
              ? _ctx.displayPhoneNumber(_ctx.buyer?.employmentDetails.previousEmployerPhone)
              : _ctx.displayPhoneNumber(_ctx.buyer?.employmentDetails.employerPhone)), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            (_ctx.employmentConfigSelected.showEmploymentTitle)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "12",
                  lg: "4"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('job-title-label')), 1),
                    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.isPrevious
              ? _ctx.buyer?.employmentDetails.previousEmploymentTitle
              : _ctx.buyer?.employmentDetails.employmentTitle), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('start-date-label')), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.isPrevious ? _ctx.previousFormattedMonthAndYear : _ctx.formattedMonthAndYear), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('annual-income-label')), 1),
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.formatCurrency(_ctx.convertIncomeToAnnual(_ctx.isPrevious))), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ], 64))
    : (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            class: "pb-0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t(_ctx.isPrevious ? 'previous-job-label' : 'current-employment-label')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            lg: "4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('employment-status-label')), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.isPrevious
              ? _ctx.buyer?.employmentDetails.previousEmploymentStatusCode
              : _ctx.buyer?.employmentDetails.employmentStatusCode), 1)
            ]),
            _: 1
          }),
          (_ctx.employmentConfigSelected.showEmployerName)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "12",
                lg: "4"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('employer-name-label')), 1),
                  _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.isPrevious
              ? _ctx.buyer?.employmentDetails.previousEmployerName
              : _ctx.buyer?.employmentDetails.employerName), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.employmentConfigSelected.showEmployerPhone)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 1,
                cols: "12",
                lg: "4"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('employer-phone-label')), 1),
                  _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.isPrevious
              ? _ctx.displayPhoneNumber(_ctx.buyer?.employmentDetails.previousEmployerPhone)
              : _ctx.displayPhoneNumber(_ctx.buyer?.employmentDetails.employerPhone)), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.employmentConfigSelected.showEmploymentTitle)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 2,
                cols: "12",
                lg: "4"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('job-title-label')), 1),
                  _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.isPrevious
              ? _ctx.buyer?.employmentDetails.previousEmploymentTitle
              : _ctx.buyer?.employmentDetails.employmentTitle), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.isPrevious)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 3,
                cols: "12",
                lg: "4"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('start-date-label')), 1),
                  _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.isPrevious ? _ctx.previousFormattedMonthAndYear : _ctx.formattedMonthAndYear), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isPrevious)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 4,
                cols: "12",
                lg: "4"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('months-on-job-label')), 1),
                  _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.buyer?.employmentDetails?.previousMonthsOnJob), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_col, {
            cols: "12",
            lg: "4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('annual-income-label')), 1),
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.formatCurrency(_ctx.convertIncomeToAnnual(_ctx.isPrevious))), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}