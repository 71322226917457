import {
  BuyerIdentity,
  ContactInfo,
  PhoneTypes,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
/**
 * Represents the editable identity fields used in the form.
 * `displayName` and `dateOfBirthDefined` are calculated, so we omit them.
 */
export type BuyerIdentityForm = BuyerIdentity;

/**
 * Default values for buyer identity form.
 */
export const defaultBuyerIdentityValues: BuyerIdentityForm = {
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: undefined,
  dateOfBirth: '',
  displayName: '',
  dateOfBirthDefined: false,
};

/**
 * Returns a fresh copy of the default buyer identity form values.
 */
export function getDefaultBuyerIdentityValues(): BuyerIdentityForm {
  return { ...defaultBuyerIdentityValues };
}

/**
 * Represents the editable contact fields used in the form.
 */
export type BuyerContactForm = ContactInfo;

/**
 * Default values for the buyer contact form.
 * `altPhone` and `altPhoneType` are optional in the model but are filled here per form defaults.
 */
export const defaultBuyerContactValues: BuyerContactForm = {
  phone: '',
  phoneType: PhoneTypes.HOME, // default per requirements 3/11/25
  email: '',
  altPhone: '',
  altPhoneType: PhoneTypes.MOBILE, // default per requirements 3/11/25
};

/**
 * Returns a fresh copy of the default buyer contact form values.
 */
export function getDefaultBuyerContactValues(): BuyerContactForm {
  return { ...defaultBuyerContactValues };
}
