<template>
  <div class="panel-content">
    <EmploymentSection
      :buyer="buyer"
      :isPrevious="false"
      :isCobuyer="isCobuyer"
      :formattedMonthAndYear="formattedMonthAndYear"
      :previousFormattedMonthAndYear="previousFormattedMonthAndYear"
    />

    <EmploymentSection
      v-if="buyer?.employmentDetails?.previousEmploymentStatusCode"
      :buyer="buyer"
      :isPrevious="true"
      :formattedMonthAndYear="formattedMonthAndYear"
      :previousFormattedMonthAndYear="previousFormattedMonthAndYear"
    />

    <v-row v-if="showExtraIncome">
      <v-col cols="12" class="pb-0">
        <p class="large light">{{ $t('extra-income-label') }}</p>
      </v-col>
      <v-col cols="12" lg="4">
        <p>{{ $t('monthly-income-label') }}</p>
        <p class="small">{{ formatCurrency(buyer?.employmentDetails.otherIncomeAmount) }}</p>
      </v-col>
      <v-col cols="12" lg="8">
        <p>{{ $t('income-source-label') }}</p>
        <p class="small">{{ buyer?.employmentDetails.otherIncomeSourceDescription }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions } from 'pinia';
import EmploymentSection from './Submit--EmploymentSection.vue';

export default defineComponent({
  name: 'Submit--EmploymentDetails',
  props: {
    buyer: {
      type: Object as PropType<Record<string, any> | undefined>,
      required: true,
    },
    formattedMonthAndYear: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    previousFormattedMonthAndYear: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    isCobuyer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EmploymentSection,
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['formatCurrency']),
  },
  computed: {
    showExtraIncome() {
      return this.buyer?.employmentDetails.otherIncomeAmount > 0;
    },
  },
});
</script>

<style lang="scss"></style>
