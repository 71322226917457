<template>
  <div class="sca-start">
    <div class="mx-auto text-center">
      <v-row no-gutters>
        <v-col cols="12">
          <sc-image
            :media="fieldsMap.get('imageWithContent.pageImage')"
            height="144"
            width="144"
            class="start-img"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <h2 class="sca-start-title">
            <sc-text :field="fieldsMap.get('imageWithContent.imagetext')" />
          </h2>
          <p class="sca-start-duration d-inline-flex">
            <sc-image :media="fieldsMap.get('imageWithContent.iconImage')" />
            <span class="ps-1"
              ><sc-rich-text :field="fieldsMap.get('imageWithContent.informationText')"
            /></span>
          </p>
          <p
            class="sca-start-instructions"
            v-html="fieldsMap.get('imageWithContent.helperText')?.value"
          />
        </v-col>
      </v-row>
      <v-row class="sca-start-cta-block mx-0">
        <v-col cols="12" class="pa-0">
          <h3 class="sca-start-cta-title">
            <sc-text :field="fieldsMap.get('applicationTypeSelection.Label')" />
            <v-tooltip
              v-model="startPageTooltip"
              location="top"
              :open-on-hover="false"
              :text="fieldsMap.get('applicationTypeSelection.optional-text')?.value"
              class="top"
              width="200"
            >
              <template v-slot:activator="{ props }">
                <sc-image
                  @click="startPageTooltip = !startPageTooltip"
                  @keydown.enter="startPageTooltip = !startPageTooltip"
                  class="tooltip-trigger"
                  :media="fieldsMap.get('applicationTypeSelection.option-icon')"
                  tabindex="0"
                  v-bind="props"
                  width="18"
                  height="18"
                />
              </template>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col cols="12">
          <div class="button-group">
            <v-btn
              class="big-blue"
              :disabled="
                !hasApplicationSchema /* prevent starting flow if any errors are present */
              "
              data-testid="sca-flow-single-applicant"
              :prepend-icon="
                fieldsMap.get('applicationTypeSelection.single-applicant.Label')?.value
                  ? `ep-icon-myself`
                  : ''
              "
              stacked
              @click="
                handleCtaClick(
                  fieldsMap.get('applicationTypeSelection.single-applicant.Label')?.value,
                  $event
                )
              "
            >
              {{ fieldsMap.get('applicationTypeSelection.single-applicant.Label')?.value }}</v-btn
            >

            <v-btn
              class="big-blue"
              :disabled="
                !hasApplicationSchema /* prevent starting flow if any errors are present */
              "
              data-testid="sca-flow-coapplicant"
              :prepend-icon="
                fieldsMap.get('applicationTypeSelection.co-applicant.Label')?.value
                  ? `ep-icon-coapplicant`
                  : ''
              "
              stacked
              @click="
                handleCtaClick(
                  fieldsMap.get('applicationTypeSelection.co-applicant.Label')?.value,
                  $event
                )
              "
            >
              {{ fieldsMap.get('applicationTypeSelection.co-applicant.Label')?.value }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Image, Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapState, mapActions } from 'pinia';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { buildMap } from '@/util/standaloneCreditAppUtils';
import RichText from '@components-vue/Foundation/Common/RichText';
import { FieldsPropType } from '@/lib/FieldTypes';
import { FlowOptionTypes } from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';

export default defineComponent({
  name: 'StandaloneCreditApp--Start',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    ScImage: Image,
    ScText: Text,
    ScRichText: RichText,
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'singleApplicantNavSteps',
      'coApplicantNavSteps',
      'nextStep',
      'stepErrors',
      'navSteps',
      'hasApplicationSchema',
    ]),
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['setFlow']),
    async handleCtaClick(btnName: string, _e: Event) {
      if (
        btnName === this.fieldsMap.get('applicationTypeSelection.single-applicant.Label')?.value
      ) {
        await this.setFlow(FlowOptionTypes.SINGLE);
      } else if (
        btnName === this.fieldsMap.get('applicationTypeSelection.co-applicant.Label')?.value
      ) {
        await this.setFlow(FlowOptionTypes.CO_APPLICANT);
      }

      if (this.nextStep) {
        await this.$router.push(this.nextStep);
      }
    },
    buildFieldsMap() {
      this.fieldsMap = buildMap(this.fields);
    },
  },
  mounted() {
    this.buildFieldsMap();
  },
  data() {
    return {
      fieldsMap: new Map(),
      startPageTooltip: false,
    };
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-start {
  margin-top: 24px;

  .sca-start-title {
    font-size: $font-size-lg;
    margin-bottom: $spacing_stack-xxs;
    color: $park-gray-0;
    font-weight: $font-weight-light;
  }

  .start-img {
    margin: 12px;
  }

  .sca-start-duration {
    color: var(--green-40);
  }

  .sca-start-instructions {
    font-size: $font-size-xxs;
    color: $park-gray-0;
  }

  .sca-start-cta-block {
    margin-top: $spacing_stack-sm;

    .sca-start-cta-title {
      color: $park-gray-0;
      font-size: $font-size-sm;
      font-weight: $font-weight-light;

      img {
        margin-left: $spacing_inline-quarck;
      }
    }
  }
}
</style>
