<template>
  <div class="sca-employment mb-6">
    <EmploymentDetailsForm
      v-model:employmentDetails="currentEmployment"
      :fields="fields"
      :fieldsMap="fieldsMap"
      :isCobuyer="isCobuyer"
      :hasError="hasError"
      :buyerEmploymentSchema="buyerEmploymentSchema"
      @clear-errors="clearErrors"
    />

    <EmploymentDetailsForm
      v-if="isPreviousEmploymentRequired"
      v-model:employmentDetails="previousEmployment"
      :isPrevious="true"
      :fields="fields"
      :fieldsMap="fieldsMap"
      :isCobuyer="isCobuyer"
      :hasError="hasError"
      :buyerEmploymentSchema="buyerEmploymentSchema"
      @clear-errors="clearErrors"
    />

    <div
      id="extra-income-section"
      v-show="employmentStatusCodeSelected"
      class="extra-income-section"
    >
      <FormHeader
        :title="fieldsMap.get(coPrefix + 'extra-income.Label')?.value"
        :note="fieldsMap.get(coPrefix + 'extra-income.Description')"
      ></FormHeader>
      <p class="mt-6 mb-4">
        {{ fieldsMap.get(coPrefix + 'extra-income.other-income-sources.Label')?.value }}
      </p>

      <v-radio-group
        v-model="otherEmployment.hasExtraIncome"
        data-testid="sca-extra-income"
        hide-details="auto"
      >
        <v-radio
          :value="YesNoOptions.NO"
          data-testid="sca-extra-income-no"
          :label="fieldsMap.get(coPrefix + 'extra-income.other-income-sources.no.Label')?.value"
          prepend-inner-icon="mdi-lock"
          @click="handleToggle('otherIncome')"
          hide-details="auto"
        >
        </v-radio>
        <v-radio
          :value="YesNoOptions.YES"
          data-testid="sca-extra-income-yes"
          :label="fieldsMap.get(coPrefix + 'extra-income.other-income-sources.yes.Label')?.value"
          prepend-inner-icon="mdi-lock"
        >
        </v-radio>
      </v-radio-group>

      <v-row v-if="otherEmployment.hasExtraIncome === YesNoOptions.YES">
        <v-col cols="12" lg="6" class="pb-0 pt-6">
          <v-text-field
            :label="buyerEmploymentSchema?.['otherIncomeAmount']?.label"
            data-testid="sca-extra-income-amount"
            v-model="formattedOtherIncomeAmountInput"
            @blur="handleOtherIncomeAmountInput(formattedOtherIncomeAmountInput, 'blur')"
            @focus="handleOtherIncomeAmountInput(formattedOtherIncomeAmountInput, 'focus')"
            @input="
              (event: InputEvent): void =>
                handleOtherIncomeAmountInput((event.target as HTMLInputElement).value, 'input')
            "
            @keydown="onlyAllowNumbers"
            maxlength="14"
            type="tel"
            :error-messages="errorMessages('otherIncomeAmount', 'Enter a monthly amount.')"
            class="mb-lg-1"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" class="py-0 pt-lg-6">
          <v-text-field
            :label="buyerEmploymentSchema?.['otherIncomeSourceDescription']?.label"
            data-testid="sca-extra-income-source"
            v-model="otherEmployment.otherIncomeSourceDescription"
            :error-messages="
              errorMessages('otherIncomeSourceDescription', 'Enter income source(s).')
            "
            class="mb-1"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapState, mapActions } from 'pinia';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { validate } from '@/util/schema/schemaValidator';
import { buildMap } from '@/util/standaloneCreditAppUtils';
import {
  EmploymentStatuses,
  IncomeTypes,
  YesNoOptions,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import {
  cleanCurrencyValues,
  ensureNumericValues,
  formatCurrencyBlur,
  formatCurrencyFocus,
  formatCurrencyInput,
  onlyAllowNumbers,
} from '@util/commonUtils';
import EmploymentDetailsForm from './EmploymentDetailsForm.vue';
import FormHeader from '../Includes/FormHeader.vue';
import { FieldsPropType } from '@/lib/FieldTypes';
import {
  getDefaultCurrentEmployment,
  getDefaultPreviousEmployment,
  getDefaultOtherIncome,
} from '@/types/StandaloneCreditApp/FormTypes/EmploymentFormType';

export default defineComponent({
  name: 'EmploymentDetailsFormContainer',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    isCobuyer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EmploymentDetailsForm,
    FormHeader,
  },
  data() {
    return {
      currentEmployment: getDefaultCurrentEmployment(),
      previousEmployment: getDefaultPreviousEmployment(),
      otherEmployment: getDefaultOtherIncome(),
      errors: {} as Record<string, string>,
      fieldsMap: new Map(),
      YesNoOptions,
    };
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'buyerEmploymentValidator',
      'buyerEmploymentSchema',
      'primaryBuyer',
      'coBuyer',
    ]),
    employmentStatusCodeSelected() {
      return !!this.currentEmployment.employmentStatusCode;
    },
    isPreviousEmploymentRequired() {
      return this.isTimeOnJobLessThan24Months || this.isUnemployed;
    },
    isTimeOnJobLessThan24Months() {
      const { employerStartMonth, employerStartYear } = this.currentEmployment;
      if (!employerStartMonth || !employerStartYear) return false;

      const month = Number(employerStartMonth);
      const year = Number(employerStartYear);
      if (isNaN(month) || isNaN(year) || month < 1 || month > 12 || year < 1900) return false;

      const startDate = new Date(year, month - 1);
      const currentDate = new Date();

      return (
        currentDate.getFullYear() - startDate.getFullYear() < 2 ||
        (currentDate.getFullYear() - startDate.getFullYear() === 2 &&
          currentDate.getMonth() <= startDate.getMonth())
      );
    },
    isUnemployed() {
      return (
        this.currentEmployment.employmentStatusCode === EmploymentStatuses.NOT_APPLICABLE || false
      );
    },
    calculateMonthsOnJob() {
      const startMonth = this.currentEmployment.employerStartMonth;
      const startYear = this.currentEmployment.employerStartYear;

      if (!startMonth || !startYear) return 0;

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      const totalMonths = (currentYear - startYear) * 12 + (currentMonth - startMonth);

      return totalMonths >= 0 ? totalMonths : 0;
    },
    coPrefix() {
      return this.isCobuyer ? 'co-applicant-' : '';
    },
    formattedOtherIncomeAmountInput: {
      get(): string {
        return this.formatCurrencyInput(this.otherEmployment.otherIncomeAmountString ?? '');
      },
      set(value: string) {
        this.otherEmployment.otherIncomeAmountString = this.formatCurrencyInput(value);
      },
    },
  },
  methods: {
    onlyAllowNumbers,
    ...mapActions(useStandaloneCreditAppStore, ['addErrors', 'setEmployment']),
    formatCurrencyBlur,
    formatCurrencyFocus,
    formatCurrencyInput,
    async submitHandler() {
      this.setMilitaryEmployerName();
      this.cleanCurrencyFields();
      this.ensureNumericFields();
      this.setPartTimeEmployment();

      this.errors = (await this.validateForm()) || {};
      if (this.hasErrors()) {
        this.handleErrors();
        return false;
      }

      this.saveEmploymentData();
      return true;
    },
    async validateForm(): Promise<Record<string, string>> {
      this.setMonthsOnJob();
      this.setOtherIncomeCode();

      const errors = await validate([this.buyerEmploymentValidator], {
        ...this.currentEmployment,
        ...this.previousEmployment,
        ...this.otherEmployment,
      });

      /* Fields that should not show validation errors- they are calculated/set
      behind the scenes NP 3/12/2025 */
      const fieldsToRemove = [
        'incomeAmount',
        'previousIncomeAmount',
        'monthsOnJob',
        'incomeIntervalCode',
        'previousIncomeIntervalCode',
      ];

      if (!errors) return {};
      fieldsToRemove.forEach((field) => delete errors[field]);

      return { ...errors };
    },
    setOtherIncomeCode() {
      if (this.otherEmployment.otherIncomeAmount) {
        this.otherEmployment.otherIncomeSourceCode = 'OTHCSTM'; // default per requirements on 3/7/2025 NP
      }
    },
    setMonthsOnJob() {
      this.currentEmployment.monthsOnJob = this.calculateMonthsOnJob;
    },
    hasError(key: string): boolean {
      return !!this.errors[key];
    },
    errorMessages(field, defaultMessage) {
      return this.hasError(field)
        ? [this.buyerEmploymentSchema?.[field]?.validationMessage || defaultMessage]
        : [];
    },
    clearErrors() {
      this.errors = {};
    },
    buildFieldsMap() {
      this.fieldsMap = buildMap(this.fields);
    },
    setMilitaryEmployerName() {
      if (this.currentEmployment.employmentStatusCode === 'Military') {
        this.currentEmployment.employerName = 'Military';
      }

      if (this.previousEmployment.previousEmploymentStatusCode === 'Military') {
        this.previousEmployment.previousEmployerName = 'Military';
      }
    },
    saveEmploymentData() {
      const employmentData = {
        ...this.currentEmployment,
        ...this.previousEmployment,
        ...this.otherEmployment,
      };

      this.setEmployment(employmentData, this.isCobuyer);
    },
    /* If less than 32 hours, set part time per requirements NP 3/13/25
      Average weekly hours only gets set if user selects Employed, then Hourly */
    setPartTimeEmployment() {
      this.toggleEmploymentStatus(
        this.currentEmployment,
        'employmentStatusCode',
        'incomeTypeCode',
        'avgWeeklyHours'
      );

      this.toggleEmploymentStatus(
        this.previousEmployment,
        'previousEmploymentStatusCode',
        'previousIncomeTypeCode',
        'previousAvgWeeklyHours'
      );
    },
    shouldToggleToPartTime(status, type, hours) {
      return status === EmploymentStatuses.FULL_TIME && type === IncomeTypes.HOURLY && hours < 32;
    },

    shouldToggleToFullTime(status, type, hours) {
      return status === EmploymentStatuses.PART_TIME && type === IncomeTypes.HOURLY && hours >= 32;
    },

    toggleEmploymentStatus(employment, statusKey, typeKey, hoursKey) {
      const currentStatus = employment[statusKey];
      const incomeType = employment[typeKey];
      const weeklyHours = employment[hoursKey];

      if (this.shouldToggleToPartTime(currentStatus, incomeType, weeklyHours)) {
        employment[statusKey] = EmploymentStatuses.PART_TIME;
      } else if (this.shouldToggleToFullTime(currentStatus, incomeType, weeklyHours)) {
        employment[statusKey] = EmploymentStatuses.FULL_TIME;
      }
    },
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
    handleErrors() {
      this.addErrors(Object.values(this.errors) as string[]);
    },
    cleanCurrencyFields() {
      cleanCurrencyValues(['hourlyPayRateString'], this.currentEmployment);
      cleanCurrencyValues(['previousHourlyPayRateString'], this.previousEmployment);

      cleanCurrencyValues(['annualIncomeString'], this.currentEmployment);
      cleanCurrencyValues(['previousAnnualIncomeString'], this.previousEmployment);

      cleanCurrencyValues(['monthlyIncomeString'], this.currentEmployment);
      cleanCurrencyValues(['previousMonthlyIncomeString'], this.previousEmployment);

      cleanCurrencyValues(['monthlyBonusPayString'], this.currentEmployment);
      cleanCurrencyValues(['previousMonthlyBonusPayString'], this.previousEmployment);

      cleanCurrencyValues(['otherIncomeAmountString'], this.otherEmployment);
    },
    ensureNumericFields() {
      ensureNumericValues(['hourlyPayRate'], this.currentEmployment);
      ensureNumericValues(['previousHourlyPayRate'], this.previousEmployment);

      ensureNumericValues(['avgWeeklyHours'], this.currentEmployment);
      ensureNumericValues(['previousAvgWeeklyHours'], this.previousEmployment);

      ensureNumericValues(['annualIncome'], this.currentEmployment);
      ensureNumericValues(['previousAnnualIncome'], this.previousEmployment);

      ensureNumericValues(['monthlyIncome'], this.currentEmployment);
      ensureNumericValues(['previousMonthlyIncome'], this.previousEmployment);

      ensureNumericValues(['monthlyBonusPay'], this.currentEmployment);
      ensureNumericValues(['previousMonthlyBonusPay'], this.previousEmployment);

      ensureNumericValues(['previousMonthsOnJob'], this.previousEmployment);

      ensureNumericValues(['otherIncomeAmount'], this.otherEmployment);
    },
    handleToggle(selectionType) {
      this.clearErrors();

      const handlers = {
        otherIncome: () => this.resetOtherIncome(),
      };

      if (handlers[selectionType]) {
        handlers[selectionType]();
      }
    },
    resetOtherIncome() {
      this.otherEmployment = getDefaultOtherIncome();
    },
    handleOtherIncomeAmountInput(value: string, eventType: 'blur' | 'focus' | 'input' = 'input') {
      this.otherEmployment.otherIncomeAmountString =
        eventType === 'blur'
          ? this.formatCurrencyBlur(value)
          : eventType === 'focus'
            ? this.formatCurrencyFocus(value)
            : this.formatCurrencyInput(value);
    },
    resetPreviousEmployment() {
      this.previousEmployment = this.getDefaultPreviousEmployment();
    },
    getDefaultCurrentEmployment() {
      return getDefaultCurrentEmployment();
    },
    getDefaultPreviousEmployment() {
      return getDefaultPreviousEmployment();
    },
    getDefaultOtherIncome() {
      return getDefaultOtherIncome();
    },
    initializeCurrentEmployment(buyer) {
      this.currentEmployment = this.getDefaultCurrentEmployment();
      Object.keys(this.currentEmployment).forEach((key) => {
        this.currentEmployment[key] =
          buyer?.employmentDetails?.[key] || this.currentEmployment[key];
      });
    },
    initializePreviousEmployment(buyer) {
      this.previousEmployment = this.getDefaultPreviousEmployment();
      Object.keys(this.previousEmployment).forEach((key) => {
        this.previousEmployment[key] =
          buyer?.employmentDetails?.[key] || this.previousEmployment[key];
      });
    },
    initializeOtherEmployment(buyer) {
      this.otherEmployment = this.getDefaultOtherIncome();
      Object.keys(this.otherEmployment).forEach((key) => {
        this.otherEmployment[key] = buyer?.employmentDetails?.[key] || this.otherEmployment[key];
      });
    },
  },
  async mounted() {
    this.buildFieldsMap();
    const buyer = this.isCobuyer ? this.coBuyer : this.primaryBuyer;
    this.initializeCurrentEmployment(buyer);
    this.initializePreviousEmployment(buyer);
    this.initializeOtherEmployment(buyer);
  },
  watch: {
    isPreviousEmploymentRequired(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        this.resetPreviousEmployment();
      }
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.extra-income-section {
  margin-top: $spacing_stack-md;
}
</style>
