<template>
  <v-row>
    <v-col cols="12" lg="6" xl="4" class="py-0">
      <v-text-field
        v-model="identityValues.firstName"
        :label="buyerIdentitySchema?.firstName?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.firstName}`"
        :error-messages="
          hasError('firstName') ? buyerIdentitySchema?.firstName?.validationMessage : []
        "
      />
    </v-col>
    <v-col cols="12" lg="6" xl="4" class="py-0">
      <v-text-field
        v-model="identityValues.middleName"
        :label="buyerIdentitySchema?.middleName?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.middleName}`"
        :error-messages="
          hasError('middleName') ? buyerIdentitySchema?.middleName?.validationMessage : []
        "
      />
    </v-col>
    <v-col cols="12" lg="6" xl="4" class="py-0">
      <v-text-field
        v-model="identityValues.lastName"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.lastName}`"
        :label="buyerIdentitySchema?.lastName?.label"
        :error-messages="
          hasError('lastName') ? buyerIdentitySchema?.lastName?.validationMessage : []
        "
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" xl="4" class="buyerNameForm py-0">
      <!-- TODO: the label should be "inside" the select not above it
       This is happening because of the v-model value (remove it and it works)
       + the same with the "state" select -->
      <v-select
        v-model="identityValues.suffix"
        :label="buyerIdentitySchema?.suffix?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-suffix`"
        :items="suffixOptionMapper"
        item-title="text"
        item-value="value"
      />
    </v-col>
    <v-col cols="12" xl="4" class="buyerNameForm py-0">
      <v-text-field
        v-model="formattedDOB"
        @input="handleDOBInput"
        @keydown="onlyAllowNumbers"
        @paste.prevent="handlePaste"
        :label="buyerIdentitySchema?.dateOfBirth?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.dateOfBirth}`"
        type="tel"
        maxlength="10"
        :error-messages="
          hasError('dateOfBirth') ? buyerIdentitySchema?.dateOfBirth?.validationMessage : []
        "
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" lg="6" class="py-0">
      <v-text-field
        v-model="contactInfoValues.email"
        :label="buyerContactSchema?.email?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.email}`"
        type="email"
        :error-messages="hasError('email') ? buyerContactSchema?.email?.validationMessage : []"
      />
    </v-col>
    <v-col cols="12" lg="6" class="py-0">
      <v-text-field
        v-model="formattedPhone"
        @input="handlePhoneInput"
        @keydown="onlyAllowNumbers"
        @paste.prevent="handlePaste"
        :label="buyerContactSchema?.phone?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-${dataTestIdSuffixes.phone}`"
        type="tel"
        maxlength="12"
        :error-messages="hasError('phone') ? buyerContactSchema?.phone?.validationMessage : []"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { validate } from '@/util/schema/schemaValidator';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions, mapState } from 'pinia';
import { Suffixes } from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import {
  cleanDOB,
  cleanPhoneNumber,
  formatDOB,
  formatPhoneNumber,
  handlePaste,
  onlyAllowNumbers,
} from '@util/commonUtils';
import {
  defaultBuyerIdentityValues,
  defaultBuyerContactValues,
  getDefaultBuyerIdentityValues,
  getDefaultBuyerContactValues,
} from '@/types/StandaloneCreditApp/FormTypes/IdentityFormType';

export default defineComponent({
  name: 'BuyerIdentity',
  props: {
    isCobuyer: {
      type: Boolean,
      default: false,
    },
    dataTestIdSuffixes: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      identityValues: { ...defaultBuyerIdentityValues },
      contactInfoValues: { ...defaultBuyerContactValues },
      errors: {} as Record<string, string>,
    };
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'buyerIdentityValidator',
      'buyerContactValidator',
      'buyerIdentitySchema',
      'buyerContactSchema',
      'primaryBuyer',
      'coBuyer',
    ]),
    suffixOptionMapper(): Array<{ value: string; text: string }> {
      return Object.entries(Suffixes).map(([key, text]) => ({
        value: key,
        text: text,
      }));
    },
    formattedDOB: {
      get(): string {
        return this.formatDOB(this.identityValues.dateOfBirth);
      },
      set(value: string) {
        this.identityValues.dateOfBirth = this.cleanDOB(value);
      },
    },
    formattedPhone: {
      get(): string {
        return this.formatPhoneNumber(this.contactInfoValues.phone);
      },
      set(value: string) {
        this.contactInfoValues.phone = this.cleanPhoneNumber(value);
      },
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['setIdentity', 'setContactInfo']),
    async submitHandler(): Promise<Record<string, string>> {
      this.errors = (await this.validateForm()) || {};

      if (Object.keys(this.errors).length === 0) {
        this.setDisplayName();
        this.setDateOfBirthDefined();
        this.assignAltPhoneToPhone();
        this.ensureValidSuffixValue();

        const identityData = {
          ...this.identityValues,
        };

        if (this.isCobuyer) {
          this.setIdentity(identityData, true);
          this.setContactInfo(this.contactInfoValues, true);
        } else {
          this.setIdentity(identityData);
          this.setContactInfo(this.contactInfoValues);
        }
      }

      return this.errors;
    },
    async validateForm(): Promise<Record<string, string>> {
      let identityErrors = await validate([this.buyerIdentityValidator], {
        ...this.identityValues,
      });

      // if date of birth is not in the past, push date of birth error
      // todo: add this to sitecore validation?
      const now = new Date().getTime();
      const dob = new Date(this.identityValues.dateOfBirth).getTime();
      if (now - dob < 0 && !identityErrors?.dateOfBirth) {
        identityErrors = {
          ...identityErrors,
          dateOfBirth: this.buyerIdentitySchema?.dateOfBirth?.label,
        };
      }

      const contactErrors = await validate([this.buyerContactValidator], {
        ...this.contactInfoValues,
      });

      return { ...identityErrors, ...contactErrors };
    },
    setDateOfBirthDefined() {
      this.identityValues.dateOfBirthDefined = !!this.identityValues.dateOfBirth;
    },
    setDisplayName() {
      const { firstName, middleName, lastName } = this.identityValues;
      this.identityValues.displayName = `${firstName}${middleName ? ' ' + middleName : ''} ${lastName}${this.identityValues?.suffix ? ', ' + this.identityValues?.suffix : ''}`;
    },
    ensureValidSuffixValue() {
      // If suffix is none, set it to empty so it does not get submitted 3/13/25
      if (this.identityValues.suffix === 'None') {
        this.identityValues.suffix = '';
      }
    },
    assignAltPhoneToPhone() {
      this.contactInfoValues.altPhone = this.contactInfoValues.phone; // set alt phone to the same number as phone
    },
    hasError(key: string): boolean {
      return !!this.errors[key];
    },
    handleDOBInput(event: Event) {
      const target = event.target as HTMLInputElement;
      this.identityValues.dateOfBirth = this.formatDOB(target.value);
    },
    handlePhoneInput(event: Event) {
      const target = event.target as HTMLInputElement;
      this.contactInfoValues.phone = this.cleanPhoneNumber(target.value);
    },
    initializeBuyerIdentity(buyer) {
      this.identityValues = getDefaultBuyerIdentityValues();
      Object.keys(this.identityValues).forEach((key) => {
        this.identityValues[key] = buyer?.identity?.[key] || this.identityValues[key];
      });
    },
    initializeBuyerContact(buyer) {
      this.contactInfoValues = getDefaultBuyerContactValues();
      Object.keys(this.contactInfoValues).forEach((key) => {
        this.contactInfoValues[key] = buyer?.contactInfo?.[key] || this.contactInfoValues[key];
      });
    },
    cleanDOB,
    cleanPhoneNumber,
    formatDOB,
    formatPhoneNumber,
    handlePaste,
    onlyAllowNumbers,
  },
  mounted() {
    const buyer = this.isCobuyer ? this.coBuyer : this.primaryBuyer;
    this.initializeBuyerIdentity(buyer);
    this.initializeBuyerContact(buyer);
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.buyerNameForm .v-field__input {
  height: $spacing_stack-lg;
}
</style>
