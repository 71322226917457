import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sca-start" }
const _hoisted_2 = { class: "mx-auto text-center" }
const _hoisted_3 = { class: "sca-start-title" }
const _hoisted_4 = { class: "sca-start-duration d-inline-flex" }
const _hoisted_5 = { class: "ps-1" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "sca-start-cta-title" }
const _hoisted_8 = { class: "button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_row, { "no-gutters": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_sc_image, {
                media: _ctx.fieldsMap.get('imageWithContent.pageImage'),
                height: "144",
                width: "144",
                class: "start-img"
              }, null, 8, ["media"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "ma-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            class: "pa-0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_3, [
                _createVNode(_component_sc_text, {
                  field: _ctx.fieldsMap.get('imageWithContent.imagetext')
                }, null, 8, ["field"])
              ]),
              _createElementVNode("p", _hoisted_4, [
                _createVNode(_component_sc_image, {
                  media: _ctx.fieldsMap.get('imageWithContent.iconImage')
                }, null, 8, ["media"]),
                _createElementVNode("span", _hoisted_5, [
                  _createVNode(_component_sc_rich_text, {
                    field: _ctx.fieldsMap.get('imageWithContent.informationText')
                  }, null, 8, ["field"])
                ])
              ]),
              _createElementVNode("p", {
                class: "sca-start-instructions",
                innerHTML: _ctx.fieldsMap.get('imageWithContent.helperText')?.value
              }, null, 8, _hoisted_6)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "sca-start-cta-block mx-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            class: "pa-0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h3", _hoisted_7, [
                _createVNode(_component_sc_text, {
                  field: _ctx.fieldsMap.get('applicationTypeSelection.Label')
                }, null, 8, ["field"]),
                _createVNode(_component_v_tooltip, {
                  modelValue: _ctx.startPageTooltip,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.startPageTooltip) = $event)),
                  location: "top",
                  "open-on-hover": false,
                  text: _ctx.fieldsMap.get('applicationTypeSelection.optional-text')?.value,
                  class: "top",
                  width: "200"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_sc_image, _mergeProps({
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startPageTooltip = !_ctx.startPageTooltip)),
                      onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.startPageTooltip = !_ctx.startPageTooltip), ["enter"])),
                      class: "tooltip-trigger",
                      media: _ctx.fieldsMap.get('applicationTypeSelection.option-icon'),
                      tabindex: "0"
                    }, props, {
                      width: "18",
                      height: "18"
                    }), null, 16, ["media"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "text"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_v_btn, {
                  class: "big-blue",
                  disabled: 
                !_ctx.hasApplicationSchema /* prevent starting flow if any errors are present */
              ,
                  "data-testid": "sca-flow-single-applicant",
                  "prepend-icon": 
                _ctx.fieldsMap.get('applicationTypeSelection.single-applicant.Label')?.value
                  ? `ep-icon-myself`
                  : ''
              ,
                  stacked: "",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (
                _ctx.handleCtaClick(
                  _ctx.fieldsMap.get('applicationTypeSelection.single-applicant.Label')?.value,
                  $event
                )
              ))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.fieldsMap.get('applicationTypeSelection.single-applicant.Label')?.value), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "prepend-icon"]),
                _createVNode(_component_v_btn, {
                  class: "big-blue",
                  disabled: 
                !_ctx.hasApplicationSchema /* prevent starting flow if any errors are present */
              ,
                  "data-testid": "sca-flow-coapplicant",
                  "prepend-icon": 
                _ctx.fieldsMap.get('applicationTypeSelection.co-applicant.Label')?.value
                  ? `ep-icon-coapplicant`
                  : ''
              ,
                  stacked: "",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (
                _ctx.handleCtaClick(
                  _ctx.fieldsMap.get('applicationTypeSelection.co-applicant.Label')?.value,
                  $event
                )
              ))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.fieldsMap.get('applicationTypeSelection.co-applicant.Label')?.value), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "prepend-icon"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}