import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormHeader = _resolveComponent("FormHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_sc_text = _resolveComponent("sc-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: "py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormHeader, {
              title: _ctx.title,
              note: _ctx.subtitle
            }, null, 8, ["title", "note"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, { class: "sca-ss-input" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              "prepend-inner-icon": "mdi-lock",
              type: "tel",
              modelValue: _ctx.formattedSSNInput,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formattedSSNInput) = $event)),
              "data-testid": "sca-personal-details-ssn",
              label: _ctx.buyerPersonalDetailsSchema?.ssn?.label,
              "error-messages": 
          _ctx.hasError('ssn')
            ? [_ctx.buyerPersonalDetailsSchema?.ssn?.validationMessage || 'Enter a valid ssn']
            : []
        ,
              maxlength: "9",
              onKeydown: _ctx.onlyAllowNumbers,
              "hide-details": "auto",
              name: "ssn",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.maskedSSN) = $event)),
                  class: "masked-ssn"
                }, null, 512), [
                  [_vModelText, _ctx.maskedSSN]
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "label", "error-messages", "onKeydown"]),
            _createVNode(_component_sc_text, {
              field: _ctx.footnote,
              class: "ss-privacy"
            }, null, 8, ["field"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}