import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "tooltip-wrapper" }
const _hoisted_2 = { class: "logic-selector-label" }
const _hoisted_3 = {
  key: 0,
  class: "error-text"
}
const _hoisted_4 = {
  key: 0,
  id: "employed-section",
  class: "employed-section mt-6"
}
const _hoisted_5 = {
  key: 0,
  id: "pay-type-toggle",
  class: "mb-8"
}
const _hoisted_6 = { class: "logic-selector-label" }
const _hoisted_7 = {
  key: 0,
  class: "error-text"
}
const _hoisted_8 = {
  key: 1,
  id: "hourly-section",
  class: "hourly-section"
}
const _hoisted_9 = { class: "mb-4" }
const _hoisted_10 = {
  key: 2,
  id: "salary-section",
  class: "salary-section"
}
const _hoisted_11 = { class: "logic-selector-label" }
const _hoisted_12 = {
  key: 0,
  class: "error-text"
}
const _hoisted_13 = {
  key: 3,
  id: "bonus-pay"
}
const _hoisted_14 = {
  key: 0,
  class: "my-4"
}
const _hoisted_15 = {
  key: 1,
  class: "my-4"
}
const _hoisted_16 = {
  key: 2,
  class: "my-4"
}
const _hoisted_17 = {
  key: 4,
  id: "total-income",
  class: "total-income"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_FormHeader = _resolveComponent("FormHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(
      _ctx.isPrevious ? 'previous-employment employment-status-section' : 'employment-status-section'
    )
    }, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_FormHeader, { title: _ctx.getEmploymentTitle }, _createSlots({ _: 2 }, [
                (_ctx.isPrevious)
                  ? {
                      name: "tooltip",
                      fn: _withCtx(() => [
                        _createVNode(_component_sc_rich_text, {
                          field: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.previous-job-tool-tip.Label')?.value,
                          "data-testid": "previous-employer-icon--helperText"
                        }, null, 8, ["field"]),
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_v_tooltip, {
                            modelValue: _ctx.previousEmploymentTooltip,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.previousEmploymentTooltip) = $event)),
                            location: "top",
                            "open-on-hover": false,
                            text: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.previous-job-tool-tip.Label')?.value,
                            class: "top",
                            width: "210"
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_sc_image, _mergeProps({
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.previousEmploymentTooltip = !_ctx.previousEmploymentTooltip)),
                                onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.previousEmploymentTooltip = !_ctx.previousEmploymentTooltip), ["enter"])),
                                class: "tooltip-trigger",
                                media: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.previous-job-tool-tip.icon-image'),
                                tabindex: "0"
                              }, props, {
                                width: "20",
                                height: "20"
                              }), null, 16, ["media"])
                            ]),
                            _: 1
                          }, 8, ["modelValue", "text"])
                        ])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["title"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            class: "toggle-buttons pb-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.primary-job-income.Label')?.value), 1),
              _createVNode(_component_v_btn_toggle, {
                modelValue: _ctx.selectedEmploymentStatusCode,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedEmploymentStatusCode) = $event)),
                class: "logic-selector",
                mandatory: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.isPrevious
              ? _ctx.employmentStatusMapperPrevious
              : _ctx.employmentStatusMapper, (value, key, index) => {
                    return (_openBlock(), _createBlock(_component_v_btn, {
                      key: key,
                      value: value,
                      disabled: _ctx.selectedEmploymentStatusCode === value,
                      "data-testid": _ctx.getDataTestId(`employmentStatusCode-${index}`),
                      onClick: ($event: any) => (_ctx.handleToggle('employmentType', value))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(key), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "disabled", "data-testid", "onClick"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.errorMessages('employmentStatusCode', 'Select an employment status.').length)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.errorMessages('employmentStatusCode', 'Select an employment status.')[0]), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 2),
    (
      _ctx.showEmployerName || _ctx.showEmployerPhone || _ctx.showEmploymentTitle || _ctx.employmentStatusCodeSelected
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_ctx.showEmployerName)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "12",
                    lg: "6",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employerName')],
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employerName')]) = $event)),
                        label: _ctx.buyerEmploymentSchema?.[_ctx.getFieldKey('employerName')]?.label,
                        "data-testid": `sca-${_ctx.getFieldKey('employerName')}`,
                        "error-messages": _ctx.errorMessages('employerName', 'Enter a name.')
                      }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.showEmployerPhone)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: "12",
                    lg: "6",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.formattedEmployerPhone,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formattedEmployerPhone) = $event)),
                        label: _ctx.buyerEmploymentSchema?.[_ctx.getFieldKey('employerPhone')]?.label,
                        maxlength: 12,
                        type: "tel",
                        "data-testid": `sca-${_ctx.getFieldKey('employerPhone')}`,
                        "error-messages": _ctx.errorMessages('employerPhone', 'Enter a valid 10-digit phone number.'),
                        onKeydown: _ctx.onlyAllowNumbers,
                        onPaste: _withModifiers(_ctx.handlePaste, ["prevent"])
                      }, null, 8, ["modelValue", "label", "data-testid", "error-messages", "onKeydown", "onPaste"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.showEmploymentTitle && !_ctx.showEmployerName)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 2,
                    cols: "12",
                    lg: "6",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employmentTitle')],
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employmentTitle')]) = $event)),
                        label: _ctx.buyerEmploymentSchema?.[_ctx.getFieldKey('employmentTitle')]?.label,
                        "data-testid": `sca-${_ctx.getFieldKey('employmentTitle')}`,
                        "error-messages": _ctx.errorMessages('employmentTitle', 'Enter a title.')
                      }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_ctx.showEmploymentTitle && _ctx.showEmployerName)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "12",
                    lg: "6",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employmentTitle')],
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employmentTitle')]) = $event)),
                        label: _ctx.buyerEmploymentSchema?.[_ctx.getFieldKey('employmentTitle')]?.label,
                        "data-testid": `sca-${_ctx.getFieldKey('employmentTitle')}`,
                        "error-messages": _ctx.errorMessages('employmentTitle', 'Enter a title.'),
                        maxlength: 20
                      }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.employmentStatusCodeSelected && !_ctx.isPrevious)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: "6",
                    lg: "3",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.employment-start-month.Label')?.value,
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employerStartMonth')],
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employerStartMonth')]) = $event)),
                        items: _ctx.validMonthOptions,
                        "item-title": "text",
                        "item-value": "value",
                        "data-testid": `sca-${_ctx.getFieldKey('employerStartMonth')}`,
                        "error-messages": _ctx.errorMessages('employerStartMonth', 'Select a month.')
                      }, null, 8, ["label", "modelValue", "items", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : (_ctx.employmentStatusCodeSelected && _ctx.isPrevious)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 2,
                      cols: "12",
                      lg: "6",
                      class: "py-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: _ctx.buyerEmploymentSchema?.['previousMonthsOnJob']?.label,
                          modelValue: _ctx.localEmploymentDetails.previousMonthsOnJobString,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localEmploymentDetails.previousMonthsOnJobString) = $event)),
                          "data-testid": `sca-previousMonthsOnJob`,
                          onKeydown: _ctx.onlyAllowNumbers,
                          type: "tel",
                          maxlength: "3",
                          minlength: "1",
                          "error-messages": 
            _ctx.hasError('previousMonthsOnJob')
              ? [
                  _ctx.buyerEmploymentSchema?.['previousMonthsOnJob']?.validationMessage ||
                    'Previous months on job',
                ]
              : []
          
                        }, null, 8, ["label", "modelValue", "onKeydown", "error-messages"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
              (_ctx.employmentStatusCodeSelected && !_ctx.isPrevious)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 3,
                    cols: "6",
                    lg: "3",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.employment-start-year.Label')?.value,
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employerStartYear')],
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employerStartYear')]) = $event)),
                        items: _ctx.validYearOptions,
                        variant: "underlined",
                        "data-testid": `sca-${_ctx.getFieldKey('employerStartYear')}`,
                        "error-messages": _ctx.errorMessages('employerStartYear', 'Select a year.')
                      }, null, 8, ["label", "modelValue", "items", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.showIncome)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.isPrevious ? 'previous-employment pay-section' : 'pay-section')
        }, [
          (_ctx.showEmployerName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "toggle-buttons"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.Label')?.value), 1),
                        _createVNode(_component_v_btn_toggle, {
                          modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('incomeTypeCode')],
                          "onUpdate:modelValue": [
                            _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('incomeTypeCode')]) = $event)),
                            _ctx.updateIncomeType
                          ],
                          class: "logic-selector mt-1",
                          mandatory: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              value: _ctx.IncomeTypes.HOURLY,
                              disabled: _ctx.isSelectedIncomeType(_ctx.IncomeTypes.HOURLY),
                              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleToggle('incomeType', _ctx.IncomeTypes.HOURLY))),
                              "data-testid": _ctx.getToggleTypeTestId('incomeTypeCode', _ctx.IncomeTypes.HOURLY)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.hourly.Label')?.value), 1)
                              ]),
                              _: 1
                            }, 8, ["value", "disabled", "data-testid"]),
                            _createVNode(_component_v_btn, {
                              value: _ctx.IncomeTypes.SALARY,
                              disabled: _ctx.isSelectedIncomeType(_ctx.IncomeTypes.SALARY),
                              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.handleToggle('incomeType', _ctx.IncomeTypes.SALARY))),
                              "data-testid": _ctx.getToggleTypeTestId('incomeTypeCode', _ctx.IncomeTypes.SALARY)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.salary.Label')?.value), 1)
                              ]),
                              _: 1
                            }, 8, ["value", "disabled", "data-testid"])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onUpdate:modelValue"]),
                        (_ctx.hasError(_ctx.getFieldKey('incomeTypeCode')))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.errorMessages('incomeTypeCode', 'Select a pay type.')[0]), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.isHourlyWithEmployer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.hourly-pay-notes.fieldNotes')?.value), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      xl: "4",
                      class: "py-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.hourly-pay-rate.Label')?.value,
                          modelValue: _ctx.formattedHourlyPayRateAmountInput,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formattedHourlyPayRateAmountInput) = $event)),
                          onBlur: _cache[15] || (_cache[15] = ($event: any) => (_ctx.handleHourlyPayRateAmount(_ctx.formattedHourlyPayRateAmountInput, 'blur'))),
                          onFocus: _cache[16] || (_cache[16] = ($event: any) => (_ctx.handleHourlyPayRateAmount(_ctx.formattedHourlyPayRateAmountInput, 'focus'))),
                          onInput: _cache[17] || (_cache[17] = 
              (event): void =>
                _ctx.handleHourlyPayRateAmount((event.target as HTMLInputElement).value, 'input')
            ),
                          onKeydown: _ctx.onlyAllowNumbers,
                          maxlength: "14",
                          type: "tel",
                          "error-messages": _ctx.errorMessages('hourlyPayRate', 'Enter a pay rate.'),
                          "data-testid": _ctx.getDataTestId('hourlyPayRate')
                        }, null, 8, ["label", "modelValue", "onKeydown", "error-messages", "data-testid"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      xl: "4",
                      class: "py-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.average-weekly-hours.Label')?.value,
                          modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('avgWeeklyHoursString')],
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('avgWeeklyHoursString')]) = $event)),
                          onInput: _ctx.preventNegativeValues,
                          onKeydown: _ctx.onlyAllowNumbers,
                          maxlength: "14",
                          type: "tel",
                          "error-messages": _ctx.errorMessages('avgWeeklyHours', 'Enter a number of hours.'),
                          "data-testid": _ctx.getDataTestId('avgWeeklyHours')
                        }, null, 8, ["label", "modelValue", "onInput", "onKeydown", "error-messages", "data-testid"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.shouldShowSalarySection)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "toggle-buttons"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.Label')?.value), 1),
                        _createVNode(_component_v_btn_toggle, {
                          modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('annualOrMonthly')],
                          "onUpdate:modelValue": [
                            _cache[21] || (_cache[21] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('annualOrMonthly')]) = $event)),
                            _ctx.updateIncomeInterval
                          ],
                          class: "logic-selector mt-1",
                          mandatory: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              value: _ctx.IncomeFrequency.ANNUAL,
                              onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.handleToggle('salaryType', _ctx.IncomeFrequency.ANNUAL))),
                              disabled: 
                _ctx.localEmploymentDetails[_ctx.getFieldKey('incomeIntervalCode')] === _ctx.IncomeFrequency.ANNUAL
              ,
                              "data-testid": `sca-${_ctx.getFieldKey('incomeIntervalCode')}-${_ctx.IncomeFrequency.ANNUAL.toLowerCase()}`
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.annual.Label')?.value), 1)
                              ]),
                              _: 1
                            }, 8, ["value", "disabled", "data-testid"]),
                            _createVNode(_component_v_btn, {
                              value: _ctx.IncomeFrequency.MONTHLY,
                              onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.handleToggle('salaryType', _ctx.IncomeFrequency.MONTHLY))),
                              disabled: 
                _ctx.localEmploymentDetails[_ctx.getFieldKey('incomeIntervalCode')] ===
                _ctx.IncomeFrequency.MONTHLY
              ,
                              "data-testid": `sca-${_ctx.getFieldKey('incomeIntervalCode')}-${_ctx.IncomeFrequency.MONTHLY.toLowerCase()}`
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.monthly.Label')?.value), 1)
                              ]),
                              _: 1
                            }, 8, ["value", "disabled", "data-testid"])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onUpdate:modelValue"]),
                        (_ctx.hasError(_ctx.getFieldKey('annualOrMonthly')))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.errorMessages('annualOrMonthly', 'Select an option.')[0]), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    (_ctx.isAnnualIncome)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "12",
                          lg: "6",
                          class: "py-0 mt-3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.annualIncomeAmount.Label')?.value,
                              modelValue: _ctx.formattedAnnualIncomeAmountInput,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.formattedAnnualIncomeAmountInput) = $event)),
                              onBlur: _cache[23] || (_cache[23] = ($event: any) => (_ctx.handleAnnualIncomeAmount(_ctx.formattedAnnualIncomeAmountInput, 'blur'))),
                              onFocus: _cache[24] || (_cache[24] = ($event: any) => (_ctx.handleAnnualIncomeAmount(_ctx.formattedAnnualIncomeAmountInput, 'focus'))),
                              onInput: _cache[25] || (_cache[25] = 
              (event): void =>
                _ctx.handleAnnualIncomeAmount((event.target as HTMLInputElement).value, 'input')
            ),
                              onKeydown: _ctx.onlyAllowNumbers,
                              maxlength: "14",
                              type: "tel",
                              name: "annualIncome",
                              "error-messages": _ctx.errorMessages('annualIncome', 'Enter annual income.'),
                              "data-testid": `sca-${_ctx.getFieldKey('annualIncome')}`
                            }, null, 8, ["label", "modelValue", "onKeydown", "error-messages", "data-testid"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isMonthlyIncome)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          cols: "12",
                          lg: "6",
                          class: "py-0 mt-3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.monthlyIncomeAmount.Label')?.value,
                              modelValue: _ctx.formattedMonthlyIncomeAmountInput,
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.formattedMonthlyIncomeAmountInput) = $event)),
                              onBlur: _cache[27] || (_cache[27] = ($event: any) => (_ctx.handleMonthlyIncomeAmount(_ctx.formattedMonthlyIncomeAmountInput, 'blur'))),
                              onFocus: _cache[28] || (_cache[28] = ($event: any) => (_ctx.handleMonthlyIncomeAmount(_ctx.formattedMonthlyIncomeAmountInput, 'focus'))),
                              onInput: _cache[29] || (_cache[29] = 
              (event): void =>
                _ctx.handleMonthlyIncomeAmount((event.target as HTMLInputElement).value, 'input')
            ),
                              onKeydown: _ctx.onlyAllowNumbers,
                              maxlength: "14",
                              type: "tel",
                              name: "monthlyIncome",
                              "error-messages": _ctx.errorMessages('monthlyIncome', 'Enter monthly amount.'),
                              "data-testid": `sca-${_ctx.getFieldKey('monthlyIncome')}`
                            }, null, 8, ["label", "modelValue", "onKeydown", "error-messages", "data-testid"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.shouldShowBonusPay)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                (_ctx.isHourlyWithEmployer)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.hourly-bonus-tips-commission.Label')?.value), 1))
                  : (_ctx.isAnnualPay)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.annual-bonus-tips-commission.Label')?.value), 1))
                    : (_ctx.isMonthlyPay)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.monthly-bonus-tips-commission.Label')?.value), 1))
                      : _createCommentVNode("", true),
                _createVNode(_component_v_radio_group, {
                  modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('hasBonusPay')],
                  "onUpdate:modelValue": [
                    _cache[30] || (_cache[30] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('hasBonusPay')]) = $event)),
                    _cache[31] || (_cache[31] = (selectedValue) => _ctx.handleToggle('bonusPay', selectedValue))
                  ],
                  "hide-details": "auto"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_radio, {
                      value: _ctx.YesNoOptions.NO,
                      label: 
            _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.annual-bonus-tips-commission.no.Label')?.value
          ,
                      "prepend-inner-icon": "mdi-lock",
                      "data-testid": `sca-${_ctx.getFieldKey('hasBonusPay')}-no`
                    }, null, 8, ["value", "label", "data-testid"]),
                    _createVNode(_component_v_radio, {
                      value: _ctx.YesNoOptions.YES,
                      label: 
            _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.annual-bonus-tips-commission.yes.Label')?.value
          ,
                      "prepend-inner-icon": "mdi-lock",
                      "data-testid": `sca-${_ctx.getFieldKey('hasBonusPay')}-yes`
                    }, null, 8, ["value", "label", "data-testid"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (_ctx.localEmploymentDetails[_ctx.getFieldKey('hasBonusPay')] === _ctx.YesNoOptions.YES)
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 3 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          class: "pb-0 pt-6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.additional-pay-amount.Label')?.value,
                              modelValue: _ctx.formattedMonthlyBonusPayAmountInput,
                              "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.formattedMonthlyBonusPayAmountInput) = $event)),
                              onBlur: _cache[33] || (_cache[33] = ($event: any) => (_ctx.handleMonthlyBonusPayAmount(_ctx.formattedMonthlyBonusPayAmountInput, 'blur'))),
                              onFocus: _cache[34] || (_cache[34] = ($event: any) => (_ctx.handleMonthlyBonusPayAmount(_ctx.formattedMonthlyBonusPayAmountInput, 'focus'))),
                              onInput: _cache[35] || (_cache[35] = 
              (event): void =>
                _ctx.handleMonthlyBonusPayAmount((event.target as HTMLInputElement).value, 'input')
            ),
                              onKeydown: _ctx.onlyAllowNumbers,
                              maxlength: "14",
                              type: "tel",
                              "error-messages": _ctx.errorMessages('monthlyBonusPay', 'Enter a monthly amount.'),
                              "data-testid": `sca-${_ctx.getFieldKey('monthlyBonusPay')}`
                            }, null, 8, ["label", "modelValue", "onKeydown", "error-messages", "data-testid"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.totalIncome && _ctx.showTotalIncome)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createTextVNode(" Total income (pre-tax) = "),
                _createElementVNode("strong", null, _toDisplayString(_ctx.totalIncome), 1)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}