import { generateCapiEventId } from '@/assets/js/capiFunctions';
import emitter from '@util/eventBus';

export const SendDataLayer = (
  vin: string | undefined,
  make: string | undefined,
  model: string | undefined,
  year: number | undefined,
  zipCode: string
) => {
  const obj = {
    event: 'Lead Sent',
    Source: 'SCA Submit',
    Subsource: 'Form Submitted',
    Location: zipCode,
    VOI_VIN: vin,
    VOI_Make: make,
    VOI_Model: model,
    VOI_Year: year,
    CAPI_event_id: generateCapiEventId('SA Credit App Completed'),
  };

  emitter.emit('analytics', obj);
};
