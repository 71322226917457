import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel-content" }
const _hoisted_2 = { class: "small" }
const _hoisted_3 = { class: "small" }
const _hoisted_4 = { class: "small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "4"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('down-payment-label')), 1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.formatCurrency(_ctx.additionalDetails?.downPayment)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "4"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('have-trade-label')), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.additionalDetails?.trade), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "4"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('ssn-label')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.additionalDetails?.ssn), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}