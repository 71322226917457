<template>
  <v-row>
    <v-col cols="12" class="pb-6">
      <FormHeader
        :title="fieldsMap.get(coPrefix + 'current-address.Label')?.value"
        :note="fieldsMap.get(coPrefix + 'current-address.Description')"
      />
    </v-col>
  </v-row>
  <v-row v-if="isCobuyer">
    <v-col cols="12" class="same-address-checkbox">
      <v-checkbox-btn
        v-model="currentAddress.coApplicantSameAddress"
        :label="
          fieldsMap.get('co-applicant-current-address.same-address-as-applicant.Label')?.value
        "
        class="mt-4"
        data-testid="cobuyer-same-address"
      />
    </v-col>
  </v-row>
  <BuyerAddressDetails
    :fields="fields"
    :fieldsMap="fieldsMap"
    :isCobuyer="isCobuyer"
    v-model:formValues="currentAddress"
    :buyerAddressSchema="buyerAddressSchema"
    :stateOptions="stateOptions"
    :residenceTypeOptions="residenceTypeOptions"
    :hasError="hasError"
    :dataTestIdSuffixes="dataTestIdSuffixes"
  />
  <v-row v-if="includePreviousAddresss">
    <v-col cols="12" class="pb-6">
      <FormHeader :title="fieldsMap.get(coPrefix + 'previous-address.Label')?.value">
        <template #tooltip>
          <sc-rich-text
            :field="
              fieldsMap.get(coPrefix + 'previous-address.previous-address-icon.helperText')?.value
            "
            data-testid="previous-address-icon--helperText"
          />
          <div class="tooltip-wrapper">
            <v-tooltip
              v-model="previousAddressTooltip"
              location="top"
              :open-on-hover="false"
              :text="
                fieldsMap.get(coPrefix + 'previous-address.previous-address-icon.helperText')?.value
              "
              class="top"
              width="250"
            >
              <template v-slot:activator="{ props }">
                <sc-image
                  @click="previousAddressTooltip = !previousAddressTooltip"
                  @keydown.enter="previousAddressTooltip = !previousAddressTooltip"
                  class="tooltip-trigger"
                  :media="
                    fieldsMap.get(coPrefix + 'previous-address.previous-address-icon.pageImage')
                  "
                  tabindex="0"
                  v-bind="props"
                  width="20"
                  height="20"
                />
              </template>
            </v-tooltip>
          </div>
        </template>
      </FormHeader>
    </v-col>
  </v-row>
  <BuyerAddressDetails
    v-if="includePreviousAddresss"
    :fields="fields"
    :fieldsMap="fieldsMap"
    :isCobuyer="isCobuyer"
    v-model:formValues="previousAddress"
    :buyerAddressSchema="buyerAddressSchema"
    :stateOptions="stateOptions"
    :residenceTypeOptions="residenceTypeOptionsPrevious"
    :hasError="hasError"
    :isPrevious="true"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { useCommonStore } from '@/stores/common';
import { mapState, mapActions } from 'pinia';
import { validate } from '@/util/schema/schemaValidator';
import {
  stateOptions,
  ResidenceTypeOption,
  ResidenceTypeCodes,
  residenceConfig,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import {
  getDefaultCurrentAddress,
  getDefaultPreviousAddress,
} from '@/types/StandaloneCreditApp/FormTypes/AddressFormType';
import { cleanCurrencyValues, ensureNumericValues } from '@util/commonUtils';
import FormHeader from '../Includes/FormHeader.vue';
import BuyerAddressDetails from './BuyerAddressDetails.vue';
import { FieldsPropType } from '@/lib/FieldTypes';
import { Image as ScImage } from '@sitecore-jss/sitecore-jss-vue';
import ScRichText from '@components-vue/Foundation/Common/RichText';

export default defineComponent({
  name: 'BuyerAddress',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    fieldsMap: {
      type: Map as PropType<Map<String, any>>,
      default: () => ({}),
    },
    isCobuyer: {
      type: Boolean,
      default: false,
    },
    dataTestIdSuffixes: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
  },
  components: {
    ScRichText,
    ScImage,
    FormHeader,
    BuyerAddressDetails,
  },
  data() {
    return {
      errors: {} as Record<string, string>,
      currentAddress: getDefaultCurrentAddress(),
      previousAddress: getDefaultPreviousAddress(),
      previousAddressTooltip: false,
      showCoApplicantCheckbox: false,
      stateOptions,
    };
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'buyerAddressValidator',
      'buyerAddressSchema',
      'primaryBuyerAddress',
      'primaryBuyer',
      'coBuyer',
    ]),
    calculatedMonthsAtAddress(): number {
      return this.calculateMonthsDifference(
        this.currentAddress.moveInYear,
        this.currentAddress.moveInMonth
      );
    },
    calculatedPreviousMonthsAtAddress(): number {
      return this.calculateMonthsDifference(
        this.previousAddress.previousMoveInYear,
        this.previousAddress.previousMoveInMonth
      );
    },
    includePreviousAddresss() {
      return (
        this.calculatedMonthsAtAddress < 24 &&
        this.currentAddress.moveInMonth != 0 &&
        this.currentAddress.moveInMonth != null &&
        this.currentAddress.moveInYear != 0 &&
        this.currentAddress.moveInYear != null
      );
    },
    residenceTypeOptions(): ResidenceTypeOption[] {
      return this.generateResidenceTypeOptions(this.coPrefix + 'current-address');
    },
    residenceTypeOptionsPrevious(): ResidenceTypeOption[] {
      return this.generateResidenceTypeOptions(this.coPrefix + 'previous-address');
    },
    coPrefix() {
      return this.isCobuyer ? 'co-applicant-' : '';
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['setAddress']),
    ...mapActions(useCommonStore, ['setZipCode']),
    async submitHandler(): Promise<Record<string, string>> {
      this.setAddressDurations();
      this.handleCoApplicantAddress();
      this.cleanCurrencyFields();
      this.ensureNumericFields();

      this.errors = (await this.validateForm()) || {};
      if (!this.hasErrors()) {
        this.updateAddressInStore();
      }
      return this.errors;
    },
    async validateForm(): Promise<Record<string, string>> {
      const addressErrors = await validate([this.buyerAddressValidator], {
        ...this.currentAddress,
        ...this.previousAddress,
      });

      return { ...addressErrors };
    },
    getResidenceTypeText(code: string, options: any[]): string {
      return options.find((option) => option.value === code)?.text || '';
    },
    setResidenceTypeText() {
      this.currentAddress.residenceTypeCodeText = this.getResidenceTypeText(
        this.currentAddress.residenceTypeCode,
        this.residenceTypeOptions
      );
      this.previousAddress.previousResidenceTypeCodeText = this.getResidenceTypeText(
        this.previousAddress.previousResidenceTypeCode ?? '',
        this.residenceTypeOptionsPrevious
      );
    },
    updateAddressInStore(): void {
      this.setResidenceTypeText();

      const addressData = {
        ...this.currentAddress,
        ...this.previousAddress,
      };

      this.setAddress(addressData, this.isCobuyer);
      this.setZipCode(this.currentAddress.zipCode);
    },
    calculateMonthsDifference(year?: number, month?: number): number {
      if (!year || !month) return 0;
      const now = new Date();
      const moveInDate = new Date(year, month - 1);
      const diffInMonths =
        (now.getFullYear() - moveInDate.getFullYear()) * 12 +
        (now.getMonth() - moveInDate.getMonth());
      return Math.max(diffInMonths, 0);
    },
    hasError(key: string): boolean {
      return !!this.errors[key];
    },
    hasErrors() {
      return Object.keys(this.errors).length;
    },
    generateResidenceTypeOptions(prefix: string): ResidenceTypeOption[] {
      return [
        {
          key: 'residence_type_mortage',
          value: ResidenceTypeCodes.BUYING_HOME,
        },
        {
          key: 'residence_type_rent',
          value: ResidenceTypeCodes.RENTING_LEASING,
        },
        {
          key: 'residence_type_own',
          value: ResidenceTypeCodes.OWNS_HOME_OUTRIGHT,
        },
        {
          key: 'residence_type_family',
          value: ResidenceTypeCodes.LIVING_WITH_RELATIVES,
        },
        {
          key: 'residence_type_other',
          value: ResidenceTypeCodes.UNKNOWN,
        },
      ].map(({ key, value }) => ({
        text: this.fieldsMap.get(`${prefix}.residenceTypeCode.${key}.Label`)?.value,
        value,
        ...residenceConfig[value],
      }));
    },
    copyPrimaryBuyerAddress(): void {
      if (!this.primaryBuyerAddress) return;
      const { addressLine1, addressLine2, city, state, zipCode } = this.primaryBuyerAddress;
      Object.assign(this.currentAddress, { addressLine1, addressLine2, city, state, zipCode });
    },
    setAddressDurations() {
      this.currentAddress.monthsAtAddress = this.calculatedMonthsAtAddress;
      this.previousAddress.previousMonthsAtAddress = this.calculatedPreviousMonthsAtAddress;
    },
    handleCoApplicantAddress() {
      if (this.currentAddress.coApplicantSameAddress) {
        this.copyPrimaryBuyerAddress();
      }
    },
    cleanCurrencyFields() {
      cleanCurrencyValues(['rentMortgagePaymentAmountString'], this.currentAddress);
      cleanCurrencyValues(['previousRentMortgagePaymentAmountString'], this.previousAddress);
    },
    ensureNumericFields() {
      ensureNumericValues(['rentMortgagePaymentAmount'], this.currentAddress);
      ensureNumericValues(['previousRentMortgagePaymentAmount'], this.previousAddress);
    },
    resetPreviousAddress() {
      this.previousAddress = this.getDefaultPreviousAddress();
    },
    getDefaultCurrentAddress() {
      return getDefaultCurrentAddress();
    },
    getDefaultPreviousAddress() {
      return getDefaultPreviousAddress();
    },
    initializeCurrentAddress(buyer) {
      this.currentAddress = this.getDefaultCurrentAddress();
      Object.keys(this.currentAddress).forEach((key) => {
        this.currentAddress[key] = buyer?.address?.[key] || this.getDefaultCurrentAddress()[key];
      });
    },
    initializePreviousAddress(buyer) {
      this.previousAddress = this.getDefaultPreviousAddress();
      Object.keys(this.previousAddress).forEach((key) => {
        this.previousAddress[key] = buyer?.address?.[key] || this.getDefaultPreviousAddress()[key];
      });
    },
  },
  mounted() {
    const buyer = this.isCobuyer ? this.coBuyer : this.primaryBuyer;
    this.initializeCurrentAddress(buyer);
    this.initializePreviousAddress(buyer);
  },
  watch: {
    includePreviousAddresss(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        this.resetPreviousAddress();
      }
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.same-address-checkbox {
  padding-top: 0;
  padding-bottom: $spacing_stack-nano;
  margin-top: -$spacing_stack-xxxs;
}
</style>
